<template>
  <div class="userinfo">
    <v-text-field
      v-model="username"
      label="아이디"
      autocomplete="username"
      variant="outlined"
      bg-color="white"
      readonly
    ></v-text-field>
    <div class="spacer"></div>
    <v-text-field
      v-model="email"
      label="이메일"
      autocomplete="email"
      variant="outlined"
      bg-color="white"
      readonly
    ></v-text-field>
    <div class="spacer"></div>
    <v-btn
      color="#b4b9bf"
      width="100%"
      height="56px"
      @click="logout"
    >
      <div class="btn_text">로그아웃</div>
    </v-btn>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

export default {
  components: {
  },
  data() {
    return {
      username: null,
      email: "없음",
      isLoggedIn: false,
    }
  },
  async mounted() {
    if (this.saved_menu == "") await this.set_saved_menu(this.$route.params.menu);
    axios
      .get('/api/user/user/')
      .then((res) => {
        if (res) {
          this.isLoggedIn = true;
          this.username = res.data.username;
        }
      })
      .catch(() => {
        return;
      })
  },
  methods: {
    async logout() {
      this.isLoading = true;
      await axios
        .post('/api/user/logout/', null, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        })
        .then(() => {
          this.$router.push({ name: "login" });
        });
      this.isLoading = false;
    }
  },
}
</script>

<style scoped lang="scss">
.userinfo {
  padding-left: 10px;
  padding-right: 10px;
}

.btn_text {
  font-size: 1.3rem;
  color: white;
}
</style>