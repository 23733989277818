<template>
  <div class="page">
    <div class="frame-top" :class="{ responsive: menu.w_list, short: menu.comic || menu.search, hide: menu.w_view || menu.c_view, long: menu.c_list }" ref="frametop">
      <wtoon_header v-show="menu.wtoon" />
      <comic_header v-show="menu.comic" />
      <w_list_header v-if="menu.w_list" />
      <c_list_header v-if="menu.c_list" />
      <search_header v-if="menu.search" />
      <userinfo_header v-if="menu.userinfo" />
      <u_bookmark_header v-if="menu.u_bookmark" />
    </div>
    <div class="frame-bottom" :class="{ full: menu.c_view }" @touchstart.passive="touchStart($event)" @touchend.passive="touchEnd($event)" ref="framebottom">
      <wtoon v-show="menu.wtoon" ref="wtoon" />
      <comic v-if="menu.comic" ref="comic" />
      <KeepAlive>
        <w_list v-if="menu.w_list" ref="wlist" />
      </KeepAlive>
      <KeepAlive>
        <w_view v-if="menu.w_view" />
      </KeepAlive>
      <!-- <KeepAlive> -->
        <c_list v-if="menu.c_list" ref="clist" />
      <!-- </KeepAlive> -->
      <!-- <KeepAlive> -->
        <c_view v-if="menu.c_view" />
      <!-- </KeepAlive> -->
      <search v-if="menu.search" />
      <s_result v-if="menu.s_result" />
      <userinfo v-if="menu.userinfo" />
      <u_bookmark v-if="menu.a_bookmark" />
    </div>
    <bottombar ref="bottombar" />
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from "@/stores/toon";

import bottombar from "./component/0_bottombar.vue";

import wtoon from "./component/1_wtoon.vue";
import w_list from "./component/2_w_list.vue";
import w_view from "./component/3_w_view.vue";
import comic from "./component/4_comic.vue";
import c_list from "./component/5_c_list.vue";
import c_view from "./component/6_c_view.vue";
import search from "./component/7_search.vue";
import s_result from "./component/8_s_result.vue";
import userinfo from "./component/9_userinfo.vue";
import u_bookmark from "./component/10_u_bookmark.vue";

import wtoon_header from "./component/0_wtoon/1_wtoon_header.vue";
import w_list_header from "./component/0_wtoon/2_w_list_header.vue";
import comic_header from "./component/1_comic/1_comic_header.vue";
import c_list_header from "./component/1_comic/2_c_list_header.vue";
import search_header from "./component/2_search/0_search_header.vue";
import userinfo_header from "./component/3_userinfo/0_userinfo_header.vue";
import u_bookmark_header from "./component/3_userinfo/1_u_bookmark_header.vue";

export default {
  components: {
    bottombar,
    wtoon,
    w_list,
    w_view,
    comic,
    c_list,
    c_view,
    search,
    s_result,
    userinfo,
    u_bookmark,
    wtoon_header,
    w_list_header,
    comic_header,
    c_list_header,
    search_header,
    userinfo_header,
    u_bookmark_header,
  },
  data() {
    return {
      menu: {
        wtoon: true,
        comic: false,
        w_list: false,
        w_view: false,
        c_list: false,
        c_view: false,
        search: false,
        userinfo: false,
        u_bookmark: false,
      },

      daylist: ['fav', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', '10', 'comp'],
    }
  },
  computed: {
    ...mapState(useToonStore, ['saved_menu', 'saved_submenu']),
  },
  watch: {
    $route: function (to, from) {
      if (to.params.menu !== from.params.menu) {
        if (to.params.menu == "wtoon" || to.params.menu == "comic" || to.params.menu == "search" || to.params.menu == "user") this.set_saved_menu(to.params.menu);
        this.showMenu(to.params.menu);
      }
    },
  },
  async mounted() {
    if (this.$route.params.menu == undefined) {
      this.$router.replace({ name: "main", params: {menu: "wtoon", submenu: "fav"} });
    } else {
      this.showMenu(this.$route.params.menu);
    }
    if (this.$route.params.menu == "wtoon" || this.$route.params.menu == "comic" || this.$route.params.menu == "search" || this.$route.params.menu == "user") {
      this.set_saved_menu(this.$route.params.menu);
    }
    if (this.$route.params.menu == "wtoon") this.set_saved_submenu(this.$route.params.submenu);
  },
  methods: {
    ...mapActions(useToonStore, ['set_saved_menu', 'set_saved_submenu']),
    showMenu(menu) {
      for(var m in Object.keys(this.menu)) {
        if (Object.keys(this.menu)[m] == menu) this.menu[Object.keys(this.menu)[m]] = true;
        else this.menu[Object.keys(this.menu)[m]] = false;
      }
    },
    touchStart(touch) {
      this.touchStartX = touch.touches[0].clientX;
      this.touchStartY = touch.touches[0].clientY;
    },
    touchEnd(touch) {
      if (this.$route.params.menu == "wtoon" || this.$route.params.menu == "comic" || this.$route.params.menu == "search" || this.$route.params.menu == "userinfo") {
        if (touch.target.className.includes("topbar-button-text")) return;
        if (touch.changedTouches[0].clientX - this.touchStartX > 100 && Math.abs(touch.changedTouches[0].clientY - this.touchStartY) < 50) {
          this.prevMenu(this.saved_menu, this.saved_submenu);
        } else if (touch.changedTouches[0].clientX - this.touchStartX < -100 && Math.abs(touch.changedTouches[0].clientY - this.touchStartY) < 50) {
          this.nextMenu(this.saved_menu, this.saved_submenu);
        }
      }
    },
    prevMenu(menu, submenu) {
      if (menu == "wtoon") {
        var targetDay = this.daylist[this.daylist.indexOf(submenu) - 1];
        if (targetDay != undefined) {
          this.$refs.wtoon.titlelist = this.$refs.wtoon.titlelist_filtered;
          this.set_saved_submenu(targetDay);
          this.$router.push({ name: "main", params: { menu: "wtoon", submenu: targetDay }});
        }
      } else if (menu == "comic") {
        if (submenu == "fav") {
          this.set_saved_menu('wtoon');
          this.$refs.bottombar.selected_menu = 0;
          this.$router.push({ name: "main", params: { menu: "wtoon", submenu: "fav" }});  
        } else {
          this.$refs.comic.titlelist = this.$refs.comic.titlelist_filtered;
          this.set_saved_submenu(submenu - 1);
          this.$router.push({ name: "main", params: { menu: "comic", submenu: submenu == 0 ? "fav" : parseInt(submenu) - 1 }});
        }
      } else if (menu == "search") {
        this.set_saved_menu('comic');
        this.$refs.bottombar.selected_menu = 1;
        this.$router.push({ name: 'main', params: { menu: 'comic', submenu: "fav" }})
      } else if (menu == "user") {
        this.set_saved_menu('search');
        this.$refs.bottombar.selected_menu = 2;
        this.$router.push({ name: 'main', params: { menu: 'search', submenu: "all" }})
      }
    },
    nextMenu(menu, submenu) {
      if (menu == "wtoon") {
        var targetDay = this.daylist[this.daylist.indexOf(submenu) + 1];
        if (targetDay != undefined) {
          this.$refs.wtoon.titlelist = this.$refs.wtoon.titlelist_filtered;
          this.set_saved_submenu(targetDay);
          this.$router.push({ name: "main", params: { menu: "wtoon", submenu: targetDay }});
        } else {
          this.set_saved_menu('comic');
        this.$refs.bottombar.selected_menu = 1;
          this.$router.push({ name: 'main', params: { menu: 'comic', submenu: "fav" }})
        }
      } else if (menu == "comic") {
        if (submenu == 16) {
          this.set_saved_menu('search');
        this.$refs.bottombar.selected_menu = 2;
          this.$router.push({ name: 'main', params: { menu: 'search', submenu: "all" }})
        } else {
          this.$refs.comic.titlelist = this.$refs.comic.titlelist_filtered;
          this.set_saved_submenu(submenu - 1);
          this.$router.push({ name: "main", params: { menu: "comic", submenu: submenu == "fav" ? 0 : parseInt(submenu) + 1 }});
        }
      } else if (menu == "search") {
        this.set_saved_menu('userinfo');
        this.$refs.bottombar.selected_menu = 3;
        this.$router.push({ name: 'main', params: { menu: 'userinfo', submenu: "all" }})
      } 
    },
  },
}
</script>

<style scoped lang="scss">
.page {
  .frame-top {
    background: rgba(24, 9, 101, 0.2);
  }

  .responsive {
    height: calc(44vw + 45px);
  }

  .short {
    height: 20vh;
  }

  .long {
    height: 35vh;
  }

  .hide {
    display: none;
  }

  .frame-bottom {
    position: relative;
    display: flex;
    flex-direction: column;

    padding: 0;
  }

  .full {
    height: 100vh;
  }
}
</style>

<style>
</style>