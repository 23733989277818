<template>
  <div class="u_bookmark">
    u_bookmark
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from '@/stores/toon'

export default {
  components: {
  },
  data() {
    return {}
  },
  async created() {
    if (this.saved_menu == "") await this.set_saved_menu(this.$route.params.menu);
  },
  computed: {
    ...mapState(useToonStore, ['saved_menu']),
  },
  methods: {
    ...mapActions(useToonStore, ['set_saved_menu']),
  },
}
</script>