<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  data: () => ({}),
}
</script>

<style lang="scss">
@import url(//fonts.googleapis.com/earlyaccess/nanumgothic.css);

* {
  font-family: "Nanum Gothic";
}

html {
  height: 100%;

  body {
    height: 100%;

    #app {
      height: 100%;
      padding: 0 !important;
    }
  }
}


.page {
  height: 100%;
  min-height: 100%;
  
  .frame {
    &-top {
      height: 30vh;

      .page_name {
        height: 100%;

        font-size: 3rem;
        font-weight: bold;
        color: #00d75b;
      }
    }

    &-bottom {
      min-height: 70vh;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader,
.loader:before,
.loader:after {
  background: #00d75b;
  -webkit-animation: load-animation 1s infinite ease-in-out;
  animation: load-animation 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #00d75b;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load-animation {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load-animation {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
</style>
