<template>
  <div class="w_view" ref="view">
    <div class="topbar-blank"/>
    <div
      class="topbar"
      :class="{
        stick_to_top: lastScrollPosition < 61,
        hide_to_top: !isOn_bar
      }"
    >
      <div class="button-back flex">
        <v-btn variant="text" height="60px" block @click="goBack"><v-icon icon="mdi-chevron-left" size="x-large"></v-icon></v-btn>
      </div>
      <div class="title flex">
        <div class="title-name">{{ saved_title.name }}</div>
        <div class="title-no">{{ No }}/{{ Article_no }}</div>
      </div>
      <div class="fav-icon flex">
        <v-btn v-if="saved_title.isFav == true" variant="text" height="45px" block>
          <v-icon icon="mdi-heart" color="red" @click="remove_Fav"></v-icon>
        </v-btn>
        <v-btn v-else variant="text" height="45px" block>
          <v-icon icon="mdi-heart-outline" @click="add_Fav"></v-icon>
        </v-btn>
      </div>
    </div>
    <div class="progress" v-if="isOn_progress" >
      <div
        v-for="img in imglist"
        :key="img.id"
        :style="{ width: 100 / imglist.length + 'vw', height: '0.5vh' }"
        ref="progress"
        class="progress-bar"
      ></div>
    </div>
    <div class="scroller" v-if="isOn_scroller">
      <div
        class="scroller-button-6"
        :class="{line: isOn_bar}"
        @touchstart.passive="startScroller($event, 'up')"
        @touchmove.passive="adjustScroller"
        @touchend.passive="endScroller"
        @contextmenu="doNothing"
      ></div>
      <div
        class="scroller-button-4"
        @touchstart.passive="startScroller($event, 'down')"
        @touchmove.passive="adjustScroller"
        @touchend.passive="endScroller"
        @contextmenu="doNothing"
      ></div>
    </div>
    <div class="imgs">
      <img
        v-for="(img, index) in imglist_lazy"
        :key=img.id
        :class="`${index}`"
        :src="`${baseURL_img}/${img}`"
        @click.self="toggleBar"
        @contextmenu="doNothing"
        @load="onImgLoad"
        @error="onImgFail"
      />
      <div class="scroller-info" v-if="isOn_scroller">
        <v-icon icon="mdi-brightness-auto"/>
        {{ scrollSpeed/4 }}X
      </div>
      <div v-if="isError" class="error flex"><v-icon icon="mdi-alert-circle"></v-icon></div>
      <div class="favorite-box-blank" v-if="isOn_favorite"/>
      <div
        class="favorite-box"
        v-if="isOn_favorite"
        :class="{
          stick_to_bottom: slider > 99
        }" 
      >
        <div class="favorite">
          <div
            class="favorite-title"
            v-for="title in titlelist_bookmark"
            :key="title.id"
            @click="show_Articles(title)"
          >
            <img class="favorite-title-thumbnail" :src="title.thumbnail">
            <div class="favorite-title-name">{{ title.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="shortcut" :class="{hide : !isOn_shortcut}" @click="isOn_bar = !isOn_bar">
      <v-btn
        v-if="No == Article_no"
        height="64px"
        variant="outlined"
        class="ma-1 shortcut-btn"
        @click="goHome"
      >
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn
        v-else
        height="64px"
        variant="outlined"
        class="ma-1 shortcut-btn"
        @click="goNext"
      >
        다음화<v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn
        height="64px"
        variant="outlined"
        class="ma-1 shortcut-btn"
        @click="goList"
      >
        <v-icon>mdi-format-list-bulleted-square</v-icon>
      </v-btn>
      <v-btn
        v-if="saved_title.isFav == false"
        height="64px"
        variant="outlined"
        class="ma-1 shortcut-btn"
        @click="add_Fav"
      >
        <v-icon color="red">mdi-heart</v-icon>
      </v-btn>
    </div>
    <div class="readInfo flex" :class="{hide_to_bottom : readInfo == null || readInfo_completion == 0 || isOn_progress == true || isOn_readInfo == false}" @click="goReadInfo">
      <div class="readInfo-box">
        <div class="spacer"></div>
        <div class="readInfo-name flex">
          <div class="readInfo-name-text flex">저장된 스크롤</div>
          <div v-if="isImgLoaded == true" class="readInfo-name-subtext flex">{{ parseInt(readInfo_completion / 100 * $refs.view.scrollHeight) }} / {{ $refs.view.scrollHeight }}({{ Math.round(readInfo_completion) }}%)</div>
        </div>
        <div class="reedInfo-button flex"><v-icon icon="mdi-chevron-double-down" color="white"></v-icon></div>
      </div>
    </div>
    <div v-if="isLoading" class="bg flex">
      <div class="loader"></div>
    </div>
    <div class="bottombar" :class="{ hide: !isOn_bar }">
      <div class="bottombar-blank"></div>
      <div class="bottombar-buttons">
        <v-btn variant="outlined" height="50px" block><v-icon icon="mdi-home" size="x-large" @click="goHome"></v-icon></v-btn>
        <v-btn variant="outlined" height="50px" block :disabled="No == 1" @click="goPrev"><v-icon icon="mdi-chevron-left" size="x-large"></v-icon></v-btn>
        <v-btn variant="outlined" height="50px" block :disabled="No == Article_no" @click="goNext"><div style="margin-left:12px">다음화<v-icon icon="mdi-chevron-right" size="x-large"></v-icon></div></v-btn>
        <v-btn variant="outlined" height="50px" block><v-icon icon="mdi-cog" size="x-large" @click="goSetting"></v-icon></v-btn>
        <v-btn variant="outlined" height="50px" block><v-icon icon="mdi-format-list-bulleted" size="x-large" @click="goList"></v-icon></v-btn>
      </div>
    </div>
    <div class="slider-container flex" v-if="slider < 99 || isOn_bar">
      <input
        type="range"
        min="0"
        max="100"
        v-model="slider"
        ref="slider"
        class="slider"
        :class="{atBottom: !isOn_bar}"
        @touchstart.passive="scrollOn"
        @mousedown.passive="scrollOn"
        @touchend.passive="scrollOff"
        @mouseup.passive="scrollOff"
      >
    </div>
    <setting v-if="isOn_setting" />
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from '@/stores/toon'
import axios from "axios";
import Cookies from "js-cookie";
import { nextTick } from "vue";
import setting from "./0_wtoon/4_w_view_setting.vue";

export default {
  components: {
    setting
  },
  data() {
    return {
      imglist: [],
      imglist_lazy: [],
      SuccessList: [],
      FailList: [],

      No: null,
      Article_no: null,
      readInfo: null,
      readInfo_completion: 0,

      slider: 0,
      crit_slider: 0,
      
      isLoading: true,
      isError: false,
      isScrolling: false,
      isImgLoaded: false,

      isOn_bar: true,
      isOn_progress: true,
      isOn_readInfo: false,
      isOn_shortcut: false,
      isOn_setting: false,
      isOn_favorite: false,

      lastScrollPosition: null,

      isOn_watchScrollStop: false,
      stopSecond: 0,
      lastScrollStayed: 0,
      savedScroll: 0,
      
      scroller_init_X: null,
      scroller_init_Y: null,
      scroller_init_T: null,
      scroller_X: null,
      scroller_Y: null,
      scrollSpeed: 4,
      scrollDirection: null,
      intervalId: null,
      scrollCount: 0,
      scrollInit: false,

      titlelist_bookmark: [],
      hasLoaded_bookmark: false,
    }
  },
  computed: {
    ...mapState(useToonStore, ['baseURL_img', 'saved_menu', 'saved_submenu', 'saved_filter', 'saved_title', 'saved_article', 'saved_scroll_img']),
    isOn_scroller() {
      if (this.saved_filter.autoScroll) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    $route: async function (to, from) {
      if (to.params.menu != "w_view") {
        if (from.params.menu == "w_view") this.set_saved_scroll_img(window.scrollY);
      } else if ((from.params.menu == "wtoon" || from.params.menu == "w_list")) {
        if (this.saved_scroll_img != null) {
          await nextTick();
          window.scrollTo(0, this.saved_scroll_img);
        }
      } else if (from.params.menu == to.params.menu && from.params.submenu !== to.params.submenu)  {
        window.scrollTo(0, 0);
        this.isLoading = true;
        this.isOn_progress = true;
        this.isOn_bar = true;
        this.isOn_favorite = false;
        this.hasLoaded_bookmark = false;
        this.saved_title.isLoad_title = false;

        this.readInfo_completion = 0;

        this.imglist_lazy = [];
        this.SuccessList = [];
        this.FailList = [];

        this.get_Imgs();
      }
    },
    slider: function () {
      var docHeight = this.$refs.view.scrollHeight;
      var winHeight = window.innerHeight;

      var scrollY = ((docHeight - winHeight) * this.slider) / 100;

      if (this.isScrolling == true) window.scrollTo(0, scrollY);
    },
    isOn_bar: function () {
      if (this.isOn_bar == true && this.isImgLoaded == true) this.isOn_readInfo = true;
      else if (this.isOn_bar == false && this.isImgLoaded == true) this.isOn_readInfo = false;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    this.isLoading = true;
    this.saved_article.id = this.$route.params.submenu;
    this.saved_article.isLoad = true;
    this.get_Imgs();
  },
  activated() {
    let viewportContent = "width=device-width,initial-scale=1.0,user-scalable=no"
    document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent)

    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    if (this.saved_article.isLoad == false) {
      this.slider = 0;
      this.isLoading = true;
      this.isOn_progress = true;
      this.isOn_bar = true;
      // this.$refs.slider.disabled = false;
      this.isOn_favorite = false;
      this.hasLoaded_bookmark = false;
      this.saved_title.isLoad_title = false;

      this.readInfo_completion = 0;

      this.imglist_lazy = [];
      this.SuccessList = [];
      this.FailList = [];
      
      this.isOn_shortcut = false;
      this.isImgLoaded = false;
      this.stopSecond = 0;
      this.lastScroll = 0;
      this.savedScroll = 0;
      this.get_Imgs();
    }
  },
  async deactivated() {
    window.removeEventListener("scroll", this.handleScroll);
    this.isOn_watchScrollStop = false;
    await this.saveReadinfo();
    if (this.saved_submenu == "fav") this.$parent.$parent.$refs.wtoon.get_Titles_update_bookmark();
  },
  methods: {
    ...mapActions(useToonStore, ['get_saved_title', 'set_saved_scroll_img', 'set_add_Fav', 'set_remove_Fav']),
    async get_Imgs() {
      console.log(`get_Imgs of ${this.$route.params.submenu}`)
      await axios
        .get(`/api/toon/article/info/${this.$route.params.submenu}/`)
        .then((res) => {
          if (this.saved_title.isLoad_title == false) this.get_saved_title(res.data.title);
          this.saved_article.id = this.$route.params.submenu;
          this.saved_article.isLoad = true;

          this.No = res.data.no;
          this.Article_no = res.data.article_no;
          if (res.data.imglist) {
            this.isError = false;
            this.imglist = this.return_Imglist(res.data.imglist);
            this.imglist_lazy.push(this.imglist[0]);
          } else {
            this.isError = true;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          console.log(err)
        })
      
      axios.get(`/api/toon/readinfo/read/${this.$route.params.submenu}/`).then((res) => {
        if (res.data.length > 0) {
          this.readInfo = res.data[0].id;
          this.readInfo_completion = res.data[0].completion;
        } else this.readInfo = null;
      });
    },
    goBack() {
      if (this.saved_menu == "wtoon" || this.saved_menu == null) {
        if (this.saved_submenu == "fav") this.$router.push({ name: "main", params: { menu: "wtoon", submenu: "fav" } });
        else this.$router.push({ name: "main", params: { menu: "w_list", submenu: this.saved_title.id } });
      } else if (this.saved_menu == "search") {
        this.$router.push({ name: "main", params: { menu: "w_list", submenu: this.saved_title.id } });
      }
    },
    async add_Fav() {
      await this.set_add_Fav();
      if (this.$parent.$parent.$refs.wtoon.titlelist.length > 0) {
        for (var t in this.$parent.$refs.wtoon.titlelist) {
          if (this.$parent.$refs.wtoon.titlelist[t].id == this.saved_title.id) {
            this.$parent.$refs.wtoon.titlelist[t].isFav = true;
            break;
          }
        }
      }
    },
    async remove_Fav() {
      await this.set_remove_Fav();
      if (this.$parent.$parent.$refs.wtoon.titlelist.length > 0) {
        for (var t in this.$parent.$parent.$refs.wtoon.titlelist) {
          if (this.$parent.$parent.$refs.wtoon.titlelist[t].id == this.saved_title.id) {
            this.$parent.$parent.$refs.wtoon.titlelist[t].isFav = false;
            break;
          }
        }
      }
    },
    show_Articles(title) {
      this.saveReadinfo();
      if (title.readinfoId) {
        this.$router.push({ name: 'main', params: { menu: 'w_view', submenu: title.readinfoId  }});
      } else {
        this.saved_title.isLoad_title = false;
        this.$router.push({ name: 'main', params: { menu: 'w_list', submenu: title.bookmarkId  }});
      }
    },
    goHome() {
      this.$router.push({ name: "main", params: { menu: "wtoon", submenu: (this.saved_submenu ? this.saved_submenu : "fav") } });
    },
    goPrev() {
      this.saveReadinfo();
      this.isLoading = true;
      axios
        .get(`/api/toon/article/info/${this.saved_title.id}/${this.No - 1}/`)
        .then((res) => {
          this.isImgLoaded = false;
          this.isOn_readInfo = false;
          this.$router.push({ name: "main", params: { menu:"w_view", submenu: res.data[0].id } })
        });
    },
    goNext() {
      this.saveReadinfo();
      this.isLoading = true;
      axios
        .get(`/api/toon/article/info/${this.saved_title.id}/${this.No + 1}/`)
        .then((res) => {
          this.isImgLoaded = false;
          this.isOn_readInfo = false;
          this.$router.push({ name: "main", params: { menu:"w_view", submenu: res.data[0].id } })
        });
    },
    goSetting() {
      this.isOn_setting = !this.isOn_setting;
    },
    goList() {
      this.$router.push({ name: "main", params: { menu: "w_list", submenu: this.saved_title.id } });
    },
    toggleBar() {
      console.log("toggleBar");
      if (this.isOn_bar) {
        if (window.scrollY < 61) window.scrollTo({left: 0, top: 61, behavior: "smooth"});
        else this.isOn_bar = false;
        this.$refs.slider.disabled = true;
      } else {
        this.isOn_bar = true;
        this.$refs.slider.disabled = false;
      }
    },
    startScroller(event, direction) {
      this.scrollCount = 0;
      this.scrollInit = true;
      this.scroller_init_X = event.targetTouches[0].clientX;
      this.scroller_init_Y = event.targetTouches[0].clientY;
      this.scroller_init_T = new Date();
      this.scroller_X = event.targetTouches[0].clientX;
      this.scroller_Y = event.targetTouches[0].clientY;

      if (this.scrollSpeed <= 0) this.scrollSpeed = 2;

      if (this.intervalId) clearInterval(this.intervalId);
      this.scrollDirection = direction;
      this.intervalId = setInterval(this.scrollAuto, 1);
    },
    adjustScroller(event) {
      if (this.scrollCount > 300) {
        this.scrollSpeed = Math.round(this.scrollSpeed);
        this.scrollCount = 0;
      }

      var changedX = event.changedTouches[0].clientX - this.scroller_X;

      if (Math.abs(event.changedTouches[0].clientY - this.scroller_init_Y) - Math.abs(event.changedTouches[0].clientX - this.scroller_init_X) > 20) {
        this.endScroller();
      } else {
        this.scrollSpeed += Math.min(Math.max(-0.02, changedX), 0.02);
        this.scroller_X = event.changedTouches[0].clientX;
        this.scroller_Y = event.changedTouches[0].clientY;
      }
    },
    endScroller() {
      clearInterval(this.intervalId);
      
      this.scroller_X = event.changedTouches[0].clientX;
      this.scroller_Y = event.changedTouches[0].clientY;
      
      this.scrollSpeed =  Math.round(this.scrollSpeed);

      if (Math.abs(this.scroller_init_X - this.scroller_X) < 10 && Math.abs(this.scroller_init_Y - this.scroller_Y) < 10 && new Date() - this.scroller_init_T < 100) {
        setTimeout(() => {this.toggleBar()}, 100);
      }
    },
    scrollAuto() {
      if (this.scrollInit) {
        if (this.scrollDirection == "down") window.scrollTo({left: 0, top: window.scrollY + Math.round(this.scrollSpeed*this.scrollCount/50)/2});
        else window.scrollTo({left: 0, top: window.scrollY - Math.round(this.scrollSpeed*this.scrollCount/50)/2});
      } else {
        if (this.scrollDirection == "down") window.scrollTo({left: 0, top: window.scrollY + Math.round(this.scrollSpeed)/2});
        else window.scrollTo({left: 0, top: window.scrollY - Math.round(this.scrollSpeed)/2});
      }
      
      this.scrollCount += 1;
      if (this.scrollCount > 50) {
        this.scrollInit = false;
      }
    },
    goReadInfo() {
      window.scrollTo(0, this.readInfo_completion / 100 * (this.$refs.view.scrollHeight - window.innerHeight));
      this.isOn_readInfo = false;
    },
    saveReadinfo() {
      if (this.SuccessList.length + this.FailList.length != this.imglist.length || this.slider < 5) return;

      var data = {
        title: this.saved_title.id,
        article: this.saved_article.id,
        completion: this.slider,
      };

      if (this.readInfo == null) {
        axios
          .post(`/api/toon/readinfo/create/`, data, {
            headers: {
              "X-CSRFTOKEN": Cookies.get("csrftoken"),
            },
          })
          .then((res) => {
            this.readInfo = res.data.id;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .put(`/api/toon/readinfo/update/${this.readInfo}/`, data, {
            headers: {
              "X-CSRFTOKEN": Cookies.get("csrftoken"),
            },
          })
          // .then((res) => console.log(res))
          .catch((err) => {
            console.log(err);
          });
      }
    },
    fillImglist(img_index) {
      this.$refs.progress[img_index].style.backgroundColor = "rgb(255, 255, 0)";
      this.imglist_lazy.push(this.imglist[img_index]);
    },
    onImgLoad(e) {
      if (e.target.className == 0) this.isLoading = false;

      this.SuccessList.push(e.target.className);
      if (this.SuccessList.length == this.imglist.length) {
        this.isOn_progress = false;
        this.isImgLoaded = true;
        this.isOn_readInfo = true;
        setTimeout(() => {
          if(this.isOn_bar == false) this.isOn_readInfo = false;
        }, 2000)
        this.isOn_watchScrollStop = true;
        this.watchScrollStop();

        var scrollPos = window.scrollY;
        var winHeight = window.innerHeight;
        var docHeight = this.$refs.view.scrollHeight;

        this.slider = (scrollPos / (docHeight - winHeight)) * 100;
      } else if (this.SuccessList.length + this.FailList.length == this.imglist.length) {
        this.isImgLoaded = true;
      }

      try {
        if (e.composedPath()[7].URL != "") {
          this.$refs.progress[e.target.className].style.backgroundColor = "rgb(0, 128, 0)";
          if (this.imglist_lazy.length < this.imglist.length) {
            this.fillImglist(this.imglist_lazy.length);
            this.fillImglist(this.imglist_lazy.length);
          }
        }
      } catch (e) {
        return;
      }
    },
    async onImgFail(e) {
      console.log(e.target.className + " Failed");
      this.$refs.progress[e.target.className].style.backgroundColor = "rgb(255, 0, 0)";

      if (this.FailList.includes(e.target.className) == false) {
        var temp_src = "";
        temp_src = this.imglist_lazy[e.target.className];
        this.imglist_lazy[e.target.className] = "/static/guage.png";

        await nextTick();
        this.imglist_lazy[e.target.className] = temp_src;
      }

      this.FailList.push(e.target.className);
    },
    handleScroll() {
      this.lastScrollPosition = window.scrollY;
      var winHeight = window.innerHeight;
      var docHeight = this.$refs.view.scrollHeight;

      this.slider = (this.lastScrollPosition / (docHeight - winHeight)) * 100;

      if (this.slider > 90 && this.No == this.Article_no && !this.hasLoaded_bookmark) {
        this.hasLoaded_bookmark = true;
        
        axios
          .get(`/api/toon/bookmark/unread/${this.saved_title.id}/`)
          .then(({ data }) => {
            if (data.length > 0) {
              this.titlelist_bookmark = data;
              this.isOn_favorite = true;
            }
          })
          .catch((err) => console.log(err));
      }

      if (this.slider > 99 && this.lastScrollPosition > 1000 && this.isImgLoaded == true) {
        this.isOn_shortcut = true;
      } else {
        if (this.isScrolling == false && this.lastScrollPosition > 30) this.isOn_bar = false;
        else if (this.lastScrollPosition <= 60) this.isOn_bar = true;
        this.isOn_shortcut = false;
      }

      if (Math.abs(this.slider - this.crit_slider) > 5 && this.isImgLoaded == true) {
        console.log("saved_readInfo")
        this.saveReadinfo();
        this.crit_slider = this.slider;
      }
    },
    scrollOn() {
      this.isScrolling = true;
    },
    scrollOff() {
      var docHeight = this.$refs.view.scrollHeight;
      var winHeight = window.innerHeight;
      var scrollY = ((docHeight - winHeight) * this.slider) / 100;

      window.scrollTo(0, scrollY);
      this.isScrolling = false;
    },
    watchScrollStop() {
      if (this.stopSecond > 5) {
        console.log("saved_readInfo")
        this.saveReadinfo();
        this.stopSecond = 0;
        this.savedScroll = window.scrollY;
        return;
      } else {
        if (this.lastScrollStayed == window.scrollY && this.savedScroll != window.scrollY) {
          this.stopSecond += 1;
        } else {
          this.lastScrollStayed = window.scrollY;
        }
      }
      
      if (this.isOn_watchScrollStop) {
        setTimeout(() => {
          this.watchScrollStop();
        }, 2000)
      }
    },
    return_Imglist(value) {
      if (value == null) {
        return [];
      } else {
        var list_raw = value.split("', ")
        var list = [];
        for (var g in list_raw) {
          list.push(list_raw[g].replace("['", "").replace("']", '').replaceAll("'", "").trim());
        }
        return list;
      }
    },
    doNothing(event) {
      event.preventDefault();
    },
  },
}
</script>

<style scoped lang="scss">
.w_view {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: 2;

  overflow: auto;

  .progress {
    display: flex;

    position: fixed;
    top: 0;

    z-index: 3;
  }

  .topbar {
    width: 100%;
    display: grid;
    grid-template-columns: 45px auto 45px;
    position: fixed;
    top: 0;
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 1;

    opacity: 1;
    transition: opacity 0.1s ease-out, top 0.1s ease-out;

    &-blank {
      width: 100%;
      height: 61px;
    }
    .title {
      flex-direction: column;

      &-name {
        white-space: nowrap;
      }

      &-no {
        font-size: 10px;
        color: gray;
      }
    }
  }

  .scroller {
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    background: rgba(0, 0, 0, 0.01);
    z-index: inherit;

    &-button-6 {
      height: 60%;

      &.line {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    &-button-4 {
      height: 40%;
    }
  }

  .scroller-info {
    position: fixed;
    top: v-bind("`${Math.max(0, 63 - lastScrollPosition)}px`");
    left: 2px;
    font-size: 1rem;
    z-index: inherit;
  }

  .imgs {
    position: relative;
    width: 100%;
    font-size: 0;

    img {
      width: 100%;
    }

    .error {
      width: 100%;
      height: 100vh;

      background: rgba(0, 0, 0, 0.1);

      position: absolute;
      top: 0;
      color: rgba(255, 0, 0, 0.8);
      font-size: 40px;
    }
  }
  
  .favorite {
    display: grid;
    grid-auto-flow: column;
    gap: 3px;
    background: #db8732;
    border-radius: 5px;

    overflow-x: scroll;

    &-box {
      width: 100%;
      height: 95px;
      z-index: 2;

      padding: 5px;
      overflow: hidden;

      border-radius: 5px;
      background-image: linear-gradient(var(--rot), #db32a3, #db8732);
      animation: rotate 1s linear infinite;

      opacity: 0;
      transition: opacity 0.2s ease-out;

      &.stick_to_bottom {
        position: fixed;
        bottom: 0;
        opacity: 1;
      }

      &-blank {
        height: 95px;
      }
    }

    &-title {
      width: 150px;
      height: 85px;
      position: relative;

      &-thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;

        border-radius: 5px;
      }

      &-name {
        width: 90%;
        position: absolute;
        bottom: 3px;
        right: 3px;

        color: white;
        font-size: 10px;
        font-weight: bold;
        text-shadow: 1px 1px 3px #000000, 1px -1px 3px #000000, -1px 1px 3px #000000, -1px -1px 3px #000000;
        text-align: right;
        word-break: keep-all;
      }
    }
  }

  

  .shortcut {
    width: 100vw;
    position: fixed;
    top: 60vh;
    display: inline-flex;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.2s ease-out;
    z-index: inherit;

    .shortcut-btn {
      border: 1px solid #00d75b;
      color: white;
      background: rgba(0, 215, 91, 0.4);
    }
  }

  .readInfo {
    width: 100%;
    position: fixed;
    bottom: 105px;
    transition: opacity 0.1s ease-out, bottom 0.1s ease-out;
    z-index: inherit;

    &-box {
      width: 55%;
      height: 40px;
      border-radius: 20px;
      
      display: grid;
      grid-template-columns: 25px auto 25px;

      background: #17ce5f;
    }

    &-name {
      width: 100%;
      overflow: hidden;
      flex-direction: column;

      &-text {
        color: white;
        font-size: 13px;
        font-weight: bold;
      }
      &-subtext {
        color: rgb(236, 236, 236);
        font-size: 11px;
      }
    }
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 3;
  }

  .bottombar {
    width: 100%;
    height: 95px;
    position: fixed;
    bottom: 0;
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    opacity: 1;
    transition: opacity 0.1s ease-out, top 0.1s ease-out;
    z-index: 3;

    &-blank {
      height: 35px;
    }

    &-buttons {
      height: 65px;
      display: grid;
      padding: 0 2.5% 5px 2.5%;
      grid-template-columns: 60px 60px auto 60px 60px;
      gap: 0 1vw;
      
      * {
        display: flex;
        justify-content: center;
        align-items: center;

        .v-btn {
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .slider {
    width: 95%;
    height: 20px;
    appearance: none;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    opacity: 1;
    position: fixed;
    bottom: 67.5px;
    transition: bottom 0.1s ease-out, width 0.1s ease-out, background 0.1s ease-out;
    z-index: 3;

    &-container {
      width: 100%;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 30px;
      height: 20px;
      border-radius: 2px;
      background: #00d75b;
      cursor: pointer;
      transition: height 0.1s ease-out, box-shadow 0.1s ease-out;
    }
  }

  .atBottom {
    bottom: 0;
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, 0);

    &::-webkit-slider-thumb {
      height: 2px;
      box-shadow: -200px 0 0 200px #00d75b;
    }
  }

  .stick_to_top {
    position: absolute;
  }

  .hide_to_top {
    opacity: 0;
    top: -60px;
  }

  .hide_to_bottom {
    opacity: 0;
    bottom: -60px;
  }

  .hide {
    opacity: 0;
    top: 100vh;
  }
}

@property --rot {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes rotate {
  from {
    --rot: 0deg;
  }
  to {
    --rot: 360deg;
  }
}
</style>
