<template>
  <div class="topbar" ref="topbar">
    <v-btn
      v-for="day in days"
      class="topbar-button pa-0"
      :key="day"
      variant="text"
      width="10%"
      height="45px"
      :class="{ active: isActive(day.route) }"
      @click="setTitle(day.route)"
    >
      <div class="topbar-button-text">{{ day.text }}</div>
    </v-btn>
    <div class="slider"></div>
    <div v-show="isUpdating" class="spinner"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from "@/stores/toon";

export default {
  props: ["isUpdating"],
  data() {
    return {
      days: [
        {
          "text": "★",
          "route": "fav",
        },
        {
          "text": "월",
          "route": "mon",
        },
        {
          "text": "화",
          "route": "tue",
        },
        {
          "text": "수",
          "route": "wed",
        },
        {
          "text": "목",
          "route": "thu",
        },
        {
          "text": "금",
          "route": "fri",
        },
        {
          "text": "토",
          "route": "sat",
        },
        {
          "text": "일",
          "route": "sun",
        },
        {
          "text": "10",
          "route": "10",
        },
        {
          "text": "완",
          "route": "comp",
        },
      ],
    }
  },
  computed: {
    ...mapState(useToonStore, ["saved_submenu"]),
  },
  methods: {
    ...mapActions(useToonStore, ["set_saved_submenu"]),
    async setTitle(day) {
      if (day != this.saved_submenu) {
        this.$parent.titlelist = this.$parent.titlelist_filtered;
        this.set_saved_submenu(day);
        this.$router.push({ name: "main", params: {menu: "wtoon", submenu: day }});
      } else {
        window.scrollTo({
          top: this.$parent.$parent.$refs.frametop.getBoundingClientRect().height, 
          left: 0, 
          behavior: 'smooth'
        });
      }
    },
    isActive(day) {
      if (this.saved_submenu == day) return true;
      else return false;
    }
  }
};
</script>

<style scoped lang="scss">
.topbar {
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &-button {
    &-text {
      font-size: 16px;
      font-weight: bold;
    }

    &:first-child.active ~ .slider {
      transform: translateX(0) scaleX(0.1);
    }

    &:nth-child(2).active ~ .slider {
      transform: translateX(10%) scaleX(0.1);
    }

    &:nth-child(3).active ~ .slider {
      transform: translateX(20%) scaleX(0.1);
    }

    &:nth-child(4).active ~ .slider {
      transform: translateX(30%) scaleX(0.1);
    }

    &:nth-child(5).active ~ .slider {
      transform: translateX(40%) scaleX(0.1);
    }

    &:nth-child(6).active ~ .slider {
      transform: translateX(50%) scaleX(0.1);
    }

    &:nth-child(7).active ~ .slider {
      transform: translateX(60%) scaleX(0.1);
    }

    &:nth-child(8).active ~ .slider {
      transform: translateX(70%) scaleX(0.1);
    }

    &:nth-child(9).active ~ .slider {
      transform: translateX(80%) scaleX(0.1);
    }
    
    &:nth-child(10).active ~ .slider {
      transform: translateX(90%) scaleX(0.1);
    }
  }

  .slider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #00d75b;
    transform-origin: 0 0;
    transition: transform .25s;
  }

  .active {
    color: #00d75b;
  }

  .v-btn {
    min-width: 0;
    border-radius: 0;
  }

  .spinner {
    width: 20px;
    height: 20px;
    
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 1;

    margin-left: auto;
    margin-right: 5px;
    border: 4px solid #eaf0f6;
    border-radius: 50%;
    border-top: 4px solid #00d75b;
    animation: spinner 0.75s linear infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
