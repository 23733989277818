<template>
  <div class="titleinfo">
    <div class="top-decoration"
      :class="{ show: lastScrollPosition == 0 }"
      v-bind:style="{
        'opacity': 100 - lastScrollPosition * 2 + '%',
      }"
    ></div>
    <div
      class="topbar"
      :class="{ transparent: lastScrollPosition < 10 }"
      v-bind:style="{
        'opacity': lastScrollPosition < 10 ? '100%' : lastScrollPosition - 10 + '%',
      }"
    >
      <div class="button-back flex">
        <v-btn variant="text" height="45px" block @click="goBack"><v-icon icon="mdi-chevron-left" size="x-large"></v-icon></v-btn>
      </div>
      <div class="title-name-top flex" :class="{ show: showName }">
        {{ saved_title.name }}
      </div>
      <div class="fav-icon flex">
        <v-btn variant="text" height="45px" block>
          <v-icon v-if="saved_title.isFav == true" icon="mdi-heart" color="red" @click="remove_Fav"></v-icon>
          <v-icon v-else icon="mdi-heart-outline" @click="add_Fav"></v-icon>
        </v-btn>
      </div>
    </div>
    <div class="title-thumbnail flex" ref="thumbnail">
      <div class="title-thumbnail-box">
        <img :src="saved_title.thumbnail" />
        <div class="title-genre">
          <div v-for="g in return_Genre(saved_title.genre)" :key="g.id" class="title-genre-badge flex">
              {{ g }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from "@/stores/toon";

export default {
  data() {
    return {
      lastScrollPosition: 0,
      showName: false,
    }
  },
  computed: {
    ...mapState(useToonStore, ['saved_title', 'saved_keyword', 'saved_menu', 'saved_submenu']),
  },
  created() {
    if (this.saved_title.isLoad_title == false) {
      this.get_saved_title(this.$route.params.submenu);
    }
  },
  methods: {
    ...mapActions(useToonStore, ['get_saved_title', 'set_add_Fav', 'set_remove_Fav']),
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      this.lastScrollPosition = currentScrollPosition

      if (this.$refs.thumbnail.getBoundingClientRect().height + this.$refs.thumbnail.getBoundingClientRect().y < 10) this.showName = true;
      else this.showName = false;
    },
    goBack() {
      if (this.saved_menu == "wtoon" || this.saved_menu == null) {
        this.$router.push({ name: "main", params: { menu: "wtoon", submenu: (this.saved_submenu ? this.saved_submenu : "fav") } });
        if (this.saved_submenu == "fav") this.$parent.$refs.wtoon.get_Titles_update_bookmark();
      } else if (this.saved_menu == "search") {
        this.$router.push({ name: "main", params: { menu: this.saved_menu, submenu: this.saved_keyword } });
      }
    },
    async add_Fav() {
      await this.set_add_Fav();
      if (this.$parent.$refs.wtoon.titlelist.length > 0) {
        for (var t in this.$parent.$refs.wtoon.titlelist) {
          if (this.$parent.$refs.wtoon.titlelist[t].id == this.saved_title.id) {
            this.$parent.$refs.wtoon.titlelist[t].isFav = true;
            break;
          }
        }
      }
      if (this.saved_title.isFav == false) this.saved_title.isFav = true;
    },
    async remove_Fav() {
      await this.set_remove_Fav();
      if (this.$parent.$refs.wtoon.titlelist.length > 0) {
        for (var t in this.$parent.$refs.wtoon.titlelist) {
          if (this.$parent.$refs.wtoon.titlelist[t].id == this.saved_title.id) {
            this.$parent.$refs.wtoon.titlelist[t].isFav = false;
            break;
          }
        }
      }
      if (this.saved_title.isFav) this.saved_title.isFav = false;
    },
    return_Genre(value) {
      if (value == null) {
        return [];
      } else {
        var genre_raw = value.split(',')
        var genre = [];
        for (var g in genre_raw) {
          genre.push(genre_raw[g].replace("[", "").replace("]", '').replaceAll("'", "").trim());
        }
        return genre;
      }
    },
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style scoped lang="scss">
.titleinfo {
  width: 100%;
  height: 100%;

  position: relative;

  background: white;

  .topbar {
    width: 100%;
    height: 45px;

    position: fixed;
    top: 0;
    display: flex;
    
    z-index: 1;

    background: rgb(255, 255, 255);
    border-bottom: 0.1px solid gray;

    font-size: 18px;
    font-weight: normal;
    color: black;

    .button-back {
      width: 45px;
    }

    .title-name-top {
      width: calc(100% - 90px);
      justify-content: start;
      overflow: auto;
      color: black;
      font-weight: bold;
      opacity: 0;
    }

    .fav-icon {
      width: 45px;
      font-size: 13px;

      .isFav {
        color: red;
      }
    }

    .show {
      opacity: 100%;
    }
  }

  .top-decoration {
    position: absolute;
    top: 0;
    
    width: 100%;
    height: 100px;

    background: #205e8f;
    opacity: 0;
  }

  .title-thumbnail {
    width: 100%;
    position: absolute;
    top: 45px;

    &-box {
      position: relative;
      width: 80vw;
      height: 40vw;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .title-genre {
        position: absolute;
        display: flex;
        bottom: 2px;
        right: 2px;

        &-badge {
          margin: 2px;
          padding: 1px 3px 1px 3px;
          border-radius: 4px;
          background: #205e8f;
          color: white;
          font-size: 11px;
        }
      }
    }
  }

  .transparent {
    border-bottom: 0px;
    background: rgba(255, 255, 255, 0);
    color: white;
  }

  .show {
    opacity: 100%;
  }
}
</style>
