import { defineStore } from "pinia";
import axios from "axios";
import Cookies from "js-cookie";

export const useToonStore = defineStore("toon", {
  state: () => ({
    baseURL: null,
    baseURL_img: null,
    baseURL_comic: null,
    baseURL_img_comic: null,
    saved_menu: null,
    saved_submenu: null,
    saved_scroll_title: null,
    saved_scroll_article: null,
    saved_scroll_img: null,
    saved_scroll_page: null,
    saved_scroll_search: null,
    saved_title: {
      id: null,
      isLoad_title: false,
      isLoad_titlelist: false,
      thumbnail: "",
      name: null,
      summary: null,
      genre: null,
      isFav: null,
      refreshing: null,
      refresh_task: null,
    },
    saved_article: {
      id: null,
      isLoad: false,
    },
    saved_filter: {
      autoScroll: false,
    },
    saved_filter_comic: null,
    is_auth: null,
    saved_keyword: null,
    saved_searchResult: [],
    saved_searchResult_comic: [],
  }),
  actions: {
    get_baseURL() {
      axios.get(`/api/toon/baseurl/`).then((res) => {
        this.baseURL = res.data[0].url;
      });
      axios.get(`/api/toon/baseurl_img/`).then((res) => {
        this.baseURL_img = res.data[0].url;
      });
    },
    get_baseURL_comic() {
      axios.get(`/api/toon/baseurl_comic/`).then((res) => {
        this.baseURL_comic = res.data[0].url;
      });
      axios.get(`/api/toon/baseurl_img_comic/`).then((res) => {
        this.baseURL_img_comic = res.data[0].url;
      });
    },
    set_saved_menu(menu) {
      this.saved_menu = menu;
    },
    set_saved_submenu(submenu) {
      this.saved_submenu = submenu;
    },
    set_saved_scroll_title(scroll) {
      this.saved_scroll_title = scroll;
    },
    set_saved_scroll_article(scroll) {
      this.saved_scroll_article = scroll;
    },
    set_saved_scroll_img(scroll) {
      this.saved_scroll_img = scroll;
    },
    set_saved_scroll_page(page) {
      this.saved_scroll_page = page;
    },
    set_saved_scroll_search(scroll) {
      this.saved_scroll_search = scroll;
    },
    set_saved_title(title) {
      this.saved_title.id = title.id;
      this.saved_title.isLoad_title = false;
      this.saved_title.isLoad_titlelist = false;
      this.saved_title.thumbnail = title.thumbnail;
      this.saved_title.name = title.name;
      this.saved_title.summary = title.summary;
      this.saved_title.genre = title.genre;
      this.saved_title.isFav = title.isFav;
      this.saved_title.bookmarkId = title.bookmarkId;
      this.saved_title.refreshing = title.refreshing;
      this.saved_title.refresh_task = title.refresh_task;
    },
    set_saved_filter(filter) {
      this.saved_filter = filter;
    },
    set_saved_filter_comic(filter) {
      this.saved_filter_comic = filter;
    },
    async set_add_Fav() {
      await axios
        .post(`/api/toon/bookmark/create/`, { title: this.saved_title.id }, {
          headers: {
            "X-CSRFTOKEN": Cookies.get("csrftoken"),
          },
        })
        .then((res) => {
          this.saved_title.isFav = true;
          this.saved_title.bookmarkId = res.data.id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async set_remove_Fav() {
      await axios
      .delete(`/api/toon/bookmark/delete/${this.saved_title.bookmarkId}/`, {
        headers: {
          "X-CSRFTOKEN": Cookies.get("csrftoken"),
        },
      })
      .then(() => {
        this.saved_title.isFav = false;
      })
      .catch((err) => {
        console.log(err);
      });
      
    },
    async set_add_Fav_comic() {
      await axios
        .post(`/api/toon/bookmark_comic/create/`, { title: this.saved_title.id }, {
          headers: {
            "X-CSRFTOKEN": Cookies.get("csrftoken"),
          },
        })
        .then((res) => {
          this.saved_title.isFav = true;
          this.saved_title.bookmarkId = res.data.id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async set_remove_Fav_comic() {
      await axios
      .delete(`/api/toon/bookmark_comic/delete/${this.saved_title.bookmarkId}/`, {
        headers: {
          "X-CSRFTOKEN": Cookies.get("csrftoken"),
        },
      })
      .then(() => {
        this.saved_title.isFav = false;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    set_saved_keyword(keyword) {
      this.saved_keyword = keyword;
    },
    get_saved_title(title_id) {
      console.log(`get_Title of ${title_id}`);
      axios
        .get(`/api/toon/title/info/${title_id}/`)
        .then(({ data }) => {
          if (data) {
            this.saved_title.id = data[0].id;
            this.saved_title.isLoad_title = true;
            this.saved_title.thumbnail = data[0].thumbnail;
            this.saved_title.name = data[0].name;
            this.saved_title.summary = data[0].summary;
            this.saved_title.genre = data[0].genre;
            this.saved_title.isFav = data[0].isFav;
            this.saved_title.bookmarkId = data[0].bookmarkId;
          }
        })
        .catch((err) => console.log(err));
    },
    get_saved_title_comic(title_id) {
      console.log(`get_Title_comic of ${title_id}`);
      axios
        .get(`/api/toon/title_comic/info/${title_id}/`)
        .then(({ data }) => {
          if (data) {
            this.saved_title.id = data[0].id;
            this.saved_title.isLoad_title = true;
            this.saved_title.thumbnail = data[0].thumbnail;
            this.saved_title.name = data[0].name;
            this.saved_title.summary = data[0].summary;
            this.saved_title.genre = data[0].genre;
            this.saved_title.isFav = data[0].isFav;
            this.saved_title.refreshing = data[0].refreshing;
            this.saved_title.refresh_task = data[0].refresh_task;
          }
        })
        .catch((err) => console.log(err));
    },
    get_searchResult(keyword) {
      axios.get(`/api/toon/title/search/${keyword}/`)
        .then(({ data }) => {
          if (data) {
            this.saved_searchResult = data;
          }
        })
        .catch((err) => console.log(err));
      axios.get(`/api/toon/title_comic/search/${keyword}/`)
        .then(({ data }) => {
          if (data) {
            this.saved_searchResult_comic = data;
          }
        })
        .catch((err) => console.log(err));
    },
  }
});
