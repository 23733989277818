<template>
  <div class="comic_header">
    <div class="chart-title flex">
      <div>주간 TOP</div>
      <div class="filter-icon flex" @click="show_menu_filter">
        <v-btn variant="text" height="45px" block>
          <v-icon icon="mdi-filter" color="white"></v-icon>
        </v-btn>
      </div>
    </div>
    <div class="chart-container" ref="chart">
      <div class="chart-items" v-for="(c, i) in chart" :key="c.id" @click="show_Articles(c)">
        <img class="chart-items-img" :src="`${c.thumbnail.includes('https://') ? '' : baseURL_comic}${c.thumbnail}`" />
        <div class="chart-items-rank flex">
          <div class="chart-items-rank-text flex">{{ i + 1 }}</div>
        </div>
        <div class="chart-items-title flex">
          <div class="chart-items-title-text flex">{{ c.name }}</div>
        </div>
      </div>
    </div>
    <menu_filter ref="menu_filter" />
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from '@/stores/toon'
import menu_filter from './3_menu_filter.vue'
import axios from "axios";
import { nextTick } from "vue";

export default {
  components: {
    menu_filter,
  },
  data() {
    return {
      chart : [],
      touchStartX: null,
      touchStartY: null,
      startScrollX: null,
    }
  },
  computed: {
    ...mapState(useToonStore, ['baseURL_comic', 'saved_title']),
  },
  created() {
    this.get_Chart();
  },
  methods: {
    ...mapActions(useToonStore, ['set_saved_title', 'set_saved_scroll_title']),
    show_menu_filter() {
      this.$refs.menu_filter.show();
    },
    async setChart(index) {
      for (var i in this.charts) {
        if (i == index) this.charts[i].selected = 1;
        else this.charts[i].selected = 0;
      }
      await nextTick();
      this.$refs.chart[index].scrollTo(0, 0);
      this.startScrollX = 0;
    },
    get_Chart() {
      axios
        .get(`/api/toon/chart_comic/`)
        .then((res) => {
          this.chart = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async show_Articles(title) {
      if (title.title) title.id = title.title;
      await this.set_saved_title(title);
      await this.set_saved_scroll_title(window.scrollY);
      if (this.saved_submenu == "fav" && title.readinfoId) {
        this.saved_article.isLoad = false;
        this.$router.push({ name: 'main', params: { menu: 'c_view', submenu: title.readinfoId  }});
      } else {
        this.$router.push({ name: 'main', params: { menu: 'c_list', submenu: title.id  }});
      }
    },
  },
}
</script>

<style scoped lang="scss">
.comic_header {
  width: 100%;
  height: 100%;
  position: relative;

  background: rgba(0, 0, 0, 1);

  .chart-title {
    width: 100%;
    height: 45px;
    color: white;
    font-weight: bold;
    font-size: 18px;

    .filter-icon {
      width: 45px;
      height: 45px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .chart-container {
    width: 100%;
    height: calc(100% - 45px);
    position: absolute;
    display: grid;
    grid-auto-flow: column;
    grid-template: 100% / 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2% 19.2%;
    gap: 1%;
    overflow-x: scroll;
    overflow-y: hidden;
    .chart-items {
      height: 100%;
      position: relative;
      
      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &-rank {
        width: 15%;
        height: 20%;
        position: absolute;
        top: 2px;
        left: 2px;
        background: #f7cb01;
        border-radius: 5px;

        &-text {
          color: black;
          font-size: 12px;
          font-weight: bold;
        }
      }
      
      &-title {
        position: absolute;
        bottom: 2px;
        right: 2px;

        &-text {
          text-align: right;
          word-break: keep-all;
          color: white;
          font-size: 12px;
          font-weight: bold;
          text-shadow: 1px 1px 3px #000000, 1px -1px 3px #000000, -1px 1px 3px #000000, -1px -1px 3px #000000;
        }
      }
    }
  }
}
</style>
