<template>
  <div class="wtoon" ref="wtoon" >
    <topbar :isUpdating="isUpdating" />
    <div ref="titlelist" class="titlelist">
      <div
        class="title"
        v-for="title in titlelist_filtered"
        :key="title.id"
        @click="show_Articles(title)"
      >
        <div class="info-top">
          <div class="thumbnail">
            <img :src="`${title.thumbnail.includes('https://') ? '' : baseURL}${title.thumbnail}`" />
            <div class="overlay" :class="{ overlay_isRead : title.isRead }"></div>
          </div>
          <div class="isRead-badge flex" v-if="title.isRead"><v-icon icon="mdi-check"></v-icon></div>
          <div class="fav flex" v-if="title.isFav && saved_submenu != 'fav'">
            <v-icon class="fav-outline" color="white">mdi-heart</v-icon>
            <v-icon class="fav-icon" color="red">mdi-heart</v-icon>
          </div>
        </div>
        <div class="info-bottom">
          <div class="name" :class="{ name_isRead : title.isRead }">{{ title.name }}</div>
          <div class="updatedate">{{ title.updatedate }}</div>
        </div>
      </div>
      <VueEternalLoading v-if="isInit" :load="get_Titles" v-model:is-initial="resetLoading">
        <template #loading>
            <b></b>
        </template>
        <template #no-more>
          <div class="line-box flex">
            <div class="line"></div>
          </div>
          <b></b>
        </template>
      </VueEternalLoading>
      <div v-show="isLoading" class="bg flex">
          <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from '@/stores/toon'
import axios from "axios";
import { nextTick } from "vue";

import topbar from './0_wtoon/0_topbar.vue'
import { VueEternalLoading } from "@ts-pro/vue-eternal-loading";

export default {
  components: {
    topbar,
    VueEternalLoading,
  },
  data() {
    return {
      page: 0,
      titlelist: [],
  
      isInit: false,
      isLoading: false,
      isUpdating: false,
      resetLoading: false,
      isScrolled: false,
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.get_baseURL();
    await this.get_Filters();
    if (this.$route.params.menu == "wtoon") {
      if (this.saved_submenu == null) await this.set_saved_submenu(this.$route.params.submenu);
      await this.get_Titles_init();
    }
  },
  computed: {
    ...mapState(useToonStore, ['baseURL', 'saved_submenu', 'saved_scroll_title', 'saved_article', 'saved_filter']),
    titlelist_filtered() {
      if (this.isFavActive) {
        var titlelist_temp = this.titlelist.filter((title) => {
          if (title.updated) return true;
          else return false;
        });
        return titlelist_temp;
      } else if (this.saved_filter == null) {
        return this.titlelist;
      } else {
        titlelist_temp = this.titlelist.filter((title) => {
          var isIncluded, isExcluded;
          var genre = this.return_Genre(title.genre);

          for (var i in genre) {
            if (this.saved_filter[genre[i]] == -1) {
              isExcluded = true;
              break;
            } else if (this.saved_filter[genre[i]] == 1) {
              isIncluded = true;
            }
          }

          if (isExcluded == true) return false;
          else if (isIncluded == true) return true;
          else return true;
        });
        return titlelist_temp;
      }
    },
    isFavActive() {
      if (this.saved_submenu == 'fav') return true;
      else return false;
    },
  },
  watch: {
    $route: async function (to, from) {
      if (to.params.menu != "wtoon") {
        return;
      } else if ((from.params.menu == "w_list" || from.params.menu == "w_view") && this.saved_scroll_title != null && this.titlelist.length > 0) {
        await nextTick();
        window.scrollTo(0, this.saved_scroll_title);
      } else if (from.params.menu == to.params.menu && from.params.submenu !== to.params.submenu)  {
        if (window.scrollY > this.$parent.$refs.frametop.getBoundingClientRect().height) window.scrollTo(0, this.$parent.$refs.frametop.getBoundingClientRect().height);
        this.isLoading = true;
        this.isInit = false;
        this.set_saved_submenu(to.params.submenu);
        this.page = 0;
        this.resetLoading = true;
        this.isScrolled = false;

        setTimeout(() => {
          this.scrollTo_frambottom();
        }, 300);
        await this.get_Titles_init();
      } else if (from.params.menu != "wtoon" && to.params.menu == "wtoon") {
        window.scrollTo(0, 0);
        this.isInit = false;
        this.set_saved_submenu(to.params.submenu);
        this.page = 0;
        this.resetLoading = true;
        this.isScrolled = false;
        await this.get_Titles_init();
      }
    }
  },
  methods: {
    ...mapActions(useToonStore, ['get_baseURL', 'set_saved_submenu', 'set_saved_scroll_title', 'set_saved_scroll_article', 'set_saved_scroll_img', 'set_saved_title', 'set_saved_filter']),
    async get_Filters() {
      axios
        .get(`/api/toon/filter/read/`)
        .then((res) => {
          if (res.data.length > 0) {
            var filter = {
              학원: res.data[0].school,
              액션: res.data[0].action,
              SF: res.data[0].SF,
              스토리: res.data[0].story,
              판타지: res.data[0].fantasy,
              "BL/백합": res.data[0].bl_les,
              "개그/코미디": res.data[0].gag,
              "연애/순정": res.data[0].purelove,
              드라마: res.data[0].drama,
              로맨스: res.data[0].romance,
              시대극: res.data[0].history,
              스포츠: res.data[0].sports,
              일상: res.data[0].life,
              "추리/미스터리": res.data[0].mystery,
              "공포/스릴러": res.data[0].thriller,
              성인: res.data[0].adult,
              옴니버스: res.data[0].omni,
              에피소드: res.data[0].episode,
              무협: res.data[0].martial,
              소년: res.data[0].boy,
              기타: res.data[0].etc,
              autoScroll: res.data[0].autoScroll,
            };
            this.set_saved_filter(filter);
          }
        });
    },
    async get_Titles_init() {
      this.isLoading = true;
      
      this.page += 1;
      console.log(`get_Titles of ${this.saved_submenu} at page ${this.page}`);
      if (this.saved_submenu == "fav") {
        await axios
          .get(`/api/toon/bookmark/list/${this.page}/`)
          .then(({ data }) => {
            if (data.length > 0) {
              this.titlelist = data;
              if (data.length == 30) this.isInit = true;
            } else {
              this.titlelist = [];
            }
          })
          .catch((err) => console.log(err));
        this.isLoading = false;
      } else if (this.saved_submenu) {
        await axios
          .get(`/api/toon/title/${this.saved_submenu}/${this.page}/`)
          .then(({ data }) => {
            if (data.length > 0) {
              this.titlelist = data;
              if (data.length == 51) this.isInit = true;
            } else {
              this.titlelist = [];
            }
          })
          .catch((err) => console.log(err));
        this.isLoading = false;
      } else {
        console.log("Error");
      }
    },
    async get_Titles(action) {
      this.isLoading = true;
      
      this.page += 1;
      console.log(`get_Titles of ${this.saved_submenu} at page ${this.page}`);
      if (this.saved_submenu == "fav") {
        await axios
          .get(`/api/toon/bookmark/list/${this.page}/`)
          .then(({ data }) => {
            if (data.length > 0) {
              this.titlelist.push(...data);
              action.loaded(data.length, 30);
            }
          })
          .catch((err) => console.log(err));
        this.isLoading = false;
      } else if (this.saved_submenu) {
        await axios
          .get(`/api/toon/title/${this.saved_submenu}/${this.page}/`)
          .then(({ data }) => {
            if (data.length > 0) {
              this.titlelist.push(...data);
              action.loaded(data.length, 51);
            }
          })
          .catch((err) => console.log(err));
        this.isLoading = false;
      } else {
        console.log("Error");
      }
    },
    async get_Titles_update_bookmark() {
      if (this.isInit == false) return;

      console.log(`get_Titles_update_bookmark of ${this.saved_submenu}`);
      this.isUpdating = true;

      await axios
        .get(`/api/toon/bookmark/list/1/${this.page}/`)
        .then(({ data }) => {
          if (data.length > 0) {
            this.titlelist = data;
          } else {
            this.titlelist = [];
          }
          this.isUpdating = false;
        })
        .catch((err) => console.log(err));
    },
    async show_Articles(title) {
      if (title.title) title.id = title.title;
      await this.set_saved_title(title);
      await this.set_saved_scroll_title(window.scrollY);
      await this.set_saved_scroll_article(null);
      await this.set_saved_scroll_img(null);
      if (this.saved_submenu == "fav" && title.readinfoId) {
        this.saved_article.isLoad = false;
        this.$router.push({ name: 'main', params: { menu: 'w_view', submenu: title.readinfoId  }});
      } else {
        this.$router.push({ name: 'main', params: { menu: 'w_list', submenu: title.id  }});
      }
    },
    scrollTo_frambottom() {
      if (this.isScrolled == false) {
        window.scrollTo({
          top: this.$parent.$refs.frametop.getBoundingClientRect().height, 
          left: 0, 
          behavior: 'smooth'
        });
        this.isScrolled = true;
      }
    },
    return_Genre(value) {
      if (value == null) {
        return [];
      } else {
        var genre_raw = value.split(',')
        var genre = [];
        for (var g in genre_raw) {
          genre.push(genre_raw[g].replace("[", "").replace("]", '').replaceAll("'", "").trim());
        }
        return genre;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.wtoon {
  min-height: 100vh;

  .titlelist {
    width: 100%;
    
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 1vh 2%;

    padding: 5px 10px 65px 10px;

    .title {
      height: 12.1vh;
      display: flex;
      flex-direction: column;

      .info-top {
        width: 100%;
        height: 8vh;

        position: relative;

        .thumbnail {
          height: 100%;
          border-radius: 10px;
          position: relative;
          overflow: hidden;
          
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.05);
          }

          .overlay_isRead {
            background: rgba(0, 0, 0, 0.6);
          }
        }

        .isRead-badge {
          width: 14px;
          height: 14px;
          position: absolute;
          top: 3px;
          right: 3px;

          border-radius: 7px;

          color: white;
          background-color: red;
          font-size: 6px;
        }
        
        .fav {
          width: 20px;
          height: 20px;
          position: absolute;
          
          top: 0;
          right: 0;

          &-outline {
            font-size: 16px;
          }

          &-icon {
            font-size: 13px;
          }

          * {
            width: 100%;
            height: 100%;

            position: absolute;      
          }
        }
      }

      .info-bottom {
        .name {
          width: 100%;

          font-size: 1.7vh;
          font-weight: bold;
          white-space: nowrap;

          overflow: auto;

          -ms-overflow-style: none;
          &::-webkit-scrollbar{
            display:none;
          }
        }

        .name_isRead {
          color: gray;
        }

        .updatedate {
          color: gray;
          font-size: 0.9vh;
        }
      }
    }

    .bg {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
  
      background-color: rgba(0, 0, 0, 0.2);
    }

    .line-box {
      margin-top: 10px;
      width: 100%;
      height: 3px;

      .line {
        width: 95%;
        height: 100%;

        background: rgba(255, 0, 0, 0.5);
      }
    }
  }
}

</style>