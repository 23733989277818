<template>
  <div class="c_view" ref="view">
    <div class="topbar" :class="{ hide_to_top: !isOn_bar }">
      <div class="button-back flex">
        <v-btn variant="text" height="60px" block @click="goBack"><v-icon icon="mdi-chevron-left" size="x-large"></v-icon></v-btn>
      </div>
      <div class="title flex">
        <div class="title-name">{{ saved_title.name }}</div>
        <div class="title-no">{{ No }}/{{ Total_no }}</div>
      </div>
      <div class="fav-icon flex">
        <v-btn variant="text" height="45px" block>
          <v-icon v-if="saved_title.isFav == true" icon="mdi-heart" color="red" @click="remove_Fav"></v-icon>
          <v-icon v-else icon="mdi-heart-outline" @click="add_Fav"></v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="isOn_bar" class="page-info">{{ page }}/{{ imglist.length }}</div>
    <div ref="imgs" class="imgs flex">
      <div ref="img" v-for="img in imglistBox" :key="img.id" class="imgs-box flex">
        <img :src="img" />
      </div>
      <div v-if="isError" class="error flex"><v-icon icon="mdi-alert-circle"></v-icon></div>
    </div>
    <div class="imgs-action" @touchstart.passive="touchStart($event)" @touchmove.passive="touchMove($event)" @touchend.passive="touchEnd($event)"></div>
    <div class="shortcut" :class="{hide : !isOn_shortcut}" @click="isOn_bar = !isOn_bar">
      <v-btn
        v-if="No == Total_no"
        height="64px"
        variant="outlined"
        class="ma-1 shortcut-btn"
        @click="goHome"
      >
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn
        v-else
        height="64px"
        variant="outlined"
        class="ma-1 shortcut-btn"
        @click="goNext"
      >
        다음화<v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn
        height="64px"
        variant="outlined"
        class="ma-1 shortcut-btn"
        @click="goList"
      >
        <v-icon>mdi-format-list-bulleted-square</v-icon>
      </v-btn>
      <v-btn
        v-if="saved_title.isFav == false"
        height="64px"
        variant="outlined"
        class="ma-1 shortcut-btn"
        @click="add_Fav"
      >
        <v-icon color="red">mdi-heart</v-icon>
      </v-btn>
    </div>
    <div class="readInfo flex" :class="{hide_to_bottom : readInfo == null || readInfo_completion == null || isOn_readInfo == false}" @click="goReadInfo">
      <div class="readInfo-box">
        <div class="spacer"></div>
        <div class="readInfo-name flex">
          <div class="readInfo-name-text flex">저장된 스크롤</div>
          <div class="readInfo-name-subtext flex">{{ Math.round(readInfo_completion * imglist.length / 100) }} / {{ this.imglist.length }}</div>
        </div>
        <div class="reedInfo-button flex"><v-icon icon="mdi-chevron-double-down" color="white"></v-icon></div>
      </div>
    </div>
    <div v-if="isOn_refresh" class="bg flex" @click.self="toggleRefresh">
      <div class="refresh flex">
        <div class="refresh-box flex">
          <div class="refresh-button flex">
            <span v-if="!isRefreshing" class="mdi mdi-update" @click="exeRefresh" />
            <div v-else class="loader" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="bg flex">
      <div class="loader"></div>
    </div>
    <div class="bottombar" :class="{ hide: !isOn_bar }">
      <div class="bottombar-blank"></div>
      <div class="bottombar-buttons">
        <v-btn variant="outlined" height="40px" block><v-icon icon="mdi-home" size="x-large" @click="goHome"></v-icon></v-btn>
        <v-btn variant="outlined" height="40px" block :disabled="No == 1" @click="goPrev"><v-icon icon="mdi-chevron-left" size="x-large"></v-icon></v-btn>
        <v-btn variant="outlined" height="40px" block :disabled="No == Total_no" @click="goNext"><div style="margin-left:12px">다음화<v-icon icon="mdi-chevron-right" size="x-large"></v-icon></div></v-btn>
        <v-btn variant="outlined" height="40px" block><v-icon icon="mdi-open-in-new" size="x-large" @click="goSource"></v-icon></v-btn>
        <v-btn variant="outlined" height="40px" block><v-icon icon="mdi-update" size="x-large" @click="toggleRefresh"></v-icon></v-btn>
        <v-btn variant="outlined" height="40px" block><v-icon icon="mdi-format-list-bulleted" size="x-large" @click="goList"></v-icon></v-btn>
      </div>
    </div>
    <div class="slider-container flex">
      <input
        type="range"
        min="1"
        :max="imglist.length"
        step="1"
        v-model="page"
        ref="slider"
        class="slider"
        :class="{atBottom: !isOn_bar}"
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from '@/stores/toon'
import axios from "axios";
import Cookies from "js-cookie";
import { nextTick } from "vue";

export default {
  data() {
    return {
      imglist: [],
      imglistBox: [],

      No: null,
      Total_no: null,
      readInfo: null,
      readInfo_completion: null,

      page: 1,
      isScrolling: false,
      isZoomed: false,
      
      isLoading: true,
      isError: false,

      isOn_bar: true,
      isOn_readInfo: false,
      isOn_shortcut: false,

      touchInitX: null,
      touchInitY: null,
      touchStartX: null,
      touchStartY: null,
      touchInitT: null,
      scrollMode: "smooth",

      isOn_refresh: false,
      isRefreshing: false,
      refreshTask: null,
    }
  },
  computed: {
    ...mapState(useToonStore, ['baseURL_comic', 'baseURL_img_comic', 'saved_menu', 'saved_submenu', 'saved_title', 'saved_article', 'saved_scroll_page']),
  },
  watch: {
    $route: async function (to, from) {
      if (to.params.menu != "c_view") {
        if (from.params.menu == "c_view") this.set_saved_scroll_page(this.page);
      } else if ((from.params.menu == "comic" || from.params.menu == "c_list")) {
        if (this.saved_scroll_page != null) {
          this.page = this.saved_scroll_page;
          setTimeout(() => {
            this.scrollToPage("auto");
          }, 1);
        } else {
          this.page = 1;
        }
      } else if (from.params.menu == to.params.menu && from.params.submenu !== to.params.submenu)  {
        this.isLoading = true;
        this.isOn_bar = true;

        this.readInfo_completion = null;

        this.get_Imgs();
      }
    },
    page: function() {
      if (this.page == this.imglist.length) this.isOn_bar = true;
      this.scrollToPage(this.scrollMode);
    },
    isOn_bar: function () {
      if (this.isOn_bar == true && this.readInfo) this.isOn_readInfo = true;
      else if (this.isOn_bar == false && this.readInfo) this.isOn_readInfo = false;
    },
  },
  mounted() {
    this.isLoading = true;
    this.saved_article.id = this.$route.params.submenu;
    this.saved_article.isLoad = true;
    this.get_Imgs();
  },
  activated() {
    let viewportContent = "width=device-width,initial-scale=1.0,user-scalable=yes"
    document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent)
      
    if (this.saved_article.isLoad == false) {
      this.isOn_shortcut = false;
      this.readInfo_completion = null;
      this.page = 1;
      this.get_Imgs();
    }
    window.addEventListener('keydown', this.keydown);
  },
  async deactivated() {
    this.isOn_watchScrollStop = false;
    await this.saveReadinfo();
    if (this.saved_submenu == "fav") this.$parent.$parent.$refs.comic.get_Titles_update_bookmark();
  },
  methods: {
    ...mapActions(useToonStore, ['get_saved_title_comic', 'set_saved_scroll_page', 'set_add_Fav_comic', 'set_remove_Fav_comic']),
    keydown(event) {
      if (event.key == "F2") {
        event.preventDefault();
        this.scroll += 100;
      }
    },
    async get_Imgs() {
      console.log(`get_Imgs of ${this.$route.params.submenu}`)
      await axios
        .get(`/api/toon/article_comic/info/${this.$route.params.submenu}/`)
        .then((res) => {
          if (this.saved_title.isLoad_title == false) this.get_saved_title_comic(res.data.title);
          this.saved_article.id = this.$route.params.submenu;
          this.saved_article.isLoad = true;

          this.No = res.data.no;
          this.Total_no = res.data.total_no;
          if (res.data.imglist == "[]") {
            this.imglist = [];
            this.imglistBox = [];
            this.page = 1;
            this.isError = true;
            this.isLoading = false;
          } else if (res.data.imglist) {
            this.isError = false;
            this.imglist = this.return_Imglist(eval(res.data.imglist))
            this.imglistBox = [...Array.from(Array(this.imglist.length).keys())].map(key => key*0);
            this.LoadImg();
            this.isLoading = false;
          } else {
            this.isError = true;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          console.log(err)
        })
      
      axios.get(`/api/toon/readinfo_comic/read/${this.$route.params.submenu}/`).then((res) => {
        if (res.data.length > 0) {
          this.readInfo = res.data[0].id;
          this.readInfo_completion = res.data[0].completion;
          this.isOn_readInfo = true;
          setTimeout(() => {
            if(this.isOn_bar == false) this.isOn_readInfo = false;
          }, 2000)
        } else this.readInfo = null;
      });
    },
    async LoadImg() {
      await nextTick();
      await this.$refs.imgs.scrollTo(this.$refs.imgs.scrollWidth, 0);
      this.imglist.forEach((img, index) => {
        this.imglistBox.splice(this.imglistBox.length - index - 1, 1, img);
      });
    },
    touchStart(touch) {
      this.isScrolling = true;
      this.touchInitX = touch.touches[0].clientX;
      this.touchInitY = touch.touches[0].clientY;
      this.touchStartX = touch.touches[0].clientX;
      this.touchInitT = new Date();
    },
    touchMove(touch) {
      if (window.visualViewport.scale > 1) {
        this.isZoomed = true;
      }

      var movement = this.touchStartX - touch.changedTouches[0].clientX;
      this.$refs.imgs.scrollTo(this.$refs.imgs.scrollLeft + movement, 0);
      this.touchStartX = touch.changedTouches[0].clientX;
    },
    touchEnd(touch) {
      this.isScrolling = false;
      
      if (touch.targetTouches.length > 0) return;

      var timeGap = new Date() - this.touchInitT;
      var changedX = this.touchInitX - touch.changedTouches[0].clientX;
      var changedY = this.touchInitY - touch.changedTouches[0].clientY;

      if (this.isZoomed) {
        if (window.visualViewport.scale == 1) {
          this.isZoomed = false;
          this.scrollToPage();
          return;
        } else {
          if (((((this.$refs.imgs.scrollWidth - (this.$refs.imgs.scrollLeft + window.visualViewport.offsetLeft + this.$refs.imgs.clientWidth))/this.$refs.imgs.clientWidth) - this.page + 2) * this.$refs.imgs.clientWidth) - window.visualViewport.width < 0) {
            this.prev_Page();
            return;
          } else if (((this.$refs.imgs.scrollWidth - (this.$refs.imgs.scrollLeft + window.visualViewport.offsetLeft + this.$refs.imgs.clientWidth))/this.$refs.imgs.clientWidth) - this.page + 2 > 1) {
            this.next_Page();
            return;
          }
          return;
        }
      }
      
      if (timeGap  > 300) {
        this.page = Math.round((this.$refs.imgs.scrollWidth - (this.$refs.imgs.scrollLeft + this.$refs.imgs.clientWidth))/this.$refs.imgs.clientWidth) + 1;
        this.scrollToPage();
      } else {
        if (changedX > 20) {
          this.prev_Page();
        } else if (changedX < -20) {
          this.next_Page();
        }
      }

      if (Math.abs(changedX) <= 20 && Math.abs(changedY) <= 20) {
        if (touch.changedTouches[0].clientX < this.$refs.imgs.clientWidth * 0.2) {
          this.scrollMode = "auto";
          this.next_Page();
        } else if (touch.changedTouches[0].clientX > this.$refs.imgs.clientWidth * 0.8) {
          this.scrollMode = "auto";
          this.prev_Page();
        } else {
          this.toggleBar();
        }
      }
    },
    next_Page() {
      if (this.imglist.length > this.page) {
        this.page = parseInt(this.page) + 1;
        this.isOn_bar = false;
        this.saveReadinfo();
      } else {
        if (this.No != this.Total_no) this.goNext();
      }
    },
    // next_Page() {
    //   if (this.imglist.length > this.page) {
    //     this.page = parseInt(this.page) + 1;
    //     this.isOn_bar = false;
    //     this.saveReadinfo();
    //   } else {
    //     if (this.No != this.Total_no) this.goNext();
    //   }
    // },
    prev_Page() {
      if (this.page > 1) this.page = parseInt(this.page) - 1;
      this.isOn_bar = false;
      this.saveReadinfo();
    },
    goBack() {
      if (this.saved_menu == "comic" || this.saved_menu == null) {
        if (this.saved_submenu == "fav") this.$router.push({ name: "main", params: { menu: "comic", submenu: "fav" } });
        else this.$router.push({ name: "main", params: { menu: "c_list", submenu: this.saved_title.id } });
      } else if (this.saved_menu == "search") {
        this.$router.push({ name: "main", params: { menu: "c_list", submenu: this.saved_title.id } });
      }
    },
    async add_Fav() {
      await this.set_add_Fav_comic();
      if (this.$parent.$parent.$refs.comic.titlelist.length > 0) {
        for (var t in this.$parent.$refs.comic.titlelist) {
          if (this.$parent.$refs.comic.titlelist[t].id == this.saved_title.id) {
            this.$parent.$refs.comic.titlelist[t].isFav = true;
            break;
          }
        }
      }
    },
    async remove_Fav() {
      await this.set_remove_Fav_comic();
      if (this.$parent.$parent.$refs.comic.titlelist.length > 0) {
        for (var t in this.$parent.$refs.comic.titlelist) {
          if (this.$parent.$refs.comic.titlelist[t].id == this.saved_title.id) {
            this.$parent.$refs.comic.titlelist[t].isFav = false;
            break;
          }
        }
      }
    },
    goHome() {
      this.$router.push({ name: "main", params: { menu: "comic", submenu: (this.saved_submenu ? this.saved_submenu : "fav") } });
    },
    goPrev() {
      this.saveReadinfo();
      this.isLoading = true;
      axios
        .get(`/api/toon/article_comic/info/${this.saved_title.id}/${this.No - 1}/`)
        .then((res) => {
          this.isImgLoaded = false;
          this.isOn_readInfo = false;
          this.$router.push({ name: "main", params: { menu:"c_view", submenu: res.data[0].id } })
          this.page = 1;
        });
    },
    goNext() {
      this.saveReadinfo();
      this.isLoading = true;
      axios
        .get(`/api/toon/article_comic/info/${this.saved_title.id}/${this.No + 1}/`)
        .then((res) => {
          this.isImgLoaded = false;
          this.isOn_readInfo = false;
          this.$router.push({ name: "main", params: { menu:"c_view", submenu: res.data[0].id } })
          this.page = 1;
        });
    },
    goSource() {
      var baseURL = this.baseURL_comic;

      axios
        .get(`/api/toon/article_comic/info/${this.$route.params.submenu}/`)
        .then((res) => {
          var articleURL = res.data.href;
          window.open(baseURL + articleURL);
        });
    },
    toggleRefresh() {
      this.isOn_refresh = !this.isOn_refresh;
    },
    exeRefresh() {
      this.isRefreshing = true;

      axios
        .get(`/api/toon/refresh/article/${this.$route.params.submenu}/`)
        .then((res) => {
          this.refreshTask = res.data.celery_task;
          this.checkRefresh();
        })
        .catch((err) => {
          console.log(err);
        })
    },
    checkRefresh() {
      axios
        .get(`/api/toon/refresh/status/${this.refreshTask}/`)
        .then((res) => {
          if (res.data.state != "SUCCESS") {
            setTimeout(() => {
              this.checkRefresh();
            }, 1000);
          } else {
            this.isRefreshing = false;
            this.isOn_refresh = false;
            this.get_Imgs();
          }
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.checkRefresh();
          }, 1000);
        })
    },
    goList() {
      this.$router.push({ name: "main", params: { menu: "c_list", submenu: this.saved_title.id } });
    },
    toggleBar() {
      this.isOn_bar = !this.isOn_bar;
      if (this.isOn_bar) this.$refs.slider.disabled = false;
      else this.$refs.slider.disabled = true;
    },
    scrollToPage(behavior='smooth') {
      var scrollLeft = this.$refs.img[this.imglist.length - this.page].offsetLeft;
      this.$refs.imgs.scrollTo({
        top: 0,
        left: scrollLeft,
        behavior: behavior
      });
      this.scrollMode = "smooth";
    },
    goReadInfo() {
      this.page = Math.round(this.readInfo_completion * this.imglist.length / 100);
      this.isOn_readInfo = false;
    },
    saveReadinfo() {
      if (this.page == 1) return;

      var data = {
        title: this.saved_title.id,
        article: this.saved_article.id,
        completion: this.page / this.imglist.length * 100,
      };

      if (this.readInfo == null) {
        axios
          .post(`/api/toon/readinfo_comic/create/`, data, {
            headers: {
              "X-CSRFTOKEN": Cookies.get("csrftoken"),
            },
          })
          .then((res) => {
            this.readInfo = res.data.id;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .put(`/api/toon/readinfo_comic/update/${this.readInfo}/`, data, {
            headers: {
              "X-CSRFTOKEN": Cookies.get("csrftoken"),
            },
          })
          // .then((res) => console.log(res))
          .catch((err) => {
            console.log(err);
          });
      }
    },
    return_Imglist(value) {
      var list = [];

      if (this.baseURL_img_comic == null) {
        axios.get(`/api/toon/baseurl_img_comic/`).then((res) => {
          var baseURL_img_comic = res.data[0].url;
          for (var url in value) {
            var length = value[url].split("/", 3).join("/").length;
            list.push(baseURL_img_comic + value[url].substring(length));
          }
        });
      } else {
        for (var url in value) {
          var length = value[url].split("/", 3).join("/").length;
          list.push(this.baseURL_img_comic + value[url].substring(length));
        }
      }
      return list;
    },
  },
}

// function getPosition(string, subString, index) {
//   return string.split(subString, index).join(subString).length;
// }
</script>

<style scoped lang="scss">
.c_view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  z-index: 3;

  overflow: auto;

  .progress {
    display: flex;

    position: fixed;
    top: 0;

    z-index: 3;
  }

  .topbar {
    width: 100%;
    display: grid;
    grid-template-columns: 45px auto 45px;
    position: fixed;
    top: 0;
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 1;

    opacity: 1;
    transition: opacity 0.1s ease-out, top 0.1s ease-out;

    .title {
      flex-direction: column;

      &-name {
        white-space: nowrap;
      }

      &-no {
        font-size: 10px;
        color: gray;
      }
    }
  }

  .page-info {
    width: 100%;
    position: absolute;
    top: 60px;
    color: white;
    text-align: center;
  }
  
  .imgs {
    width: 100%;
    height: 100%;
    
    position: absolute;
    justify-content: flex-start;

    overflow: hidden;

    &-box {
      min-width: 100%;
      height: 100%;
      color: white;
    }

    &-action {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      width: 100%;
    }

    &-bg {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
    }

    &-img {
      position: relative;
      z-index: 0;
      
      img {
        width: 100%;
      }

      &-buttons {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        display: grid;
        grid-template-columns: 40% 20% 40%;
      }

      .center-button {
        width: 10%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
      }

      .prev-button {
        width: 45%;
        height: 100%;

        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .error {
      width: 100%;
      height: 100vh;

      background: rgba(0, 0, 0, 0.1);

      position: absolute;
      top: 0;
      color: rgba(255, 0, 0, 0.8);
      font-size: 40px;
    }
  }

  .shortcut {
    width: 100vw;
    position: fixed;
    top: 60vh;
    display: inline-flex;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.2s ease-out, top 0.2s ease-out;

    .shortcut-btn {
      border: 1px solid #00d75b;
      color: white;
      background: rgba(0, 215, 91, 0.4);
    }
  }

  .readInfo {
    width: 100%;
    position: fixed;
    bottom: 90px;
    transition: opacity 0.1s ease-out, bottom 0.1s ease-out;

    &-box {
      width: 55%;
      height: 40px;
      border-radius: 20px;
      
      display: grid;
      grid-template-columns: 25px auto 25px;

      background: #17ce5f;
    }

    &-name {
      width: 100%;
      overflow: hidden;
      flex-direction: column;

      &-text {
        color: white;
        font-size: 13px;
        font-weight: bold;
      }
      &-subtext {
        color: rgb(236, 236, 236);
        font-size: 11px;
      }
    }
  }

  .refresh {
    width: 120px;
    height: 120px;

    &-button {
      width: 100px;
      height: 100px;
      border: 1px solid gray;
      border-radius: 10px;

      .loader {
        margin-top: 100px;
      }

      &:active {
        background: rgba(0, 0, 0, 0.1);
      }

      span {
        font-size: 50px;
      }
    }

    &-box {
      width: 95%;
      height: 95%;
      border-radius: 10px;
      background: white;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    }
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 3;
  }

  .bottombar {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    opacity: 1;
    transition: opacity 0.1s ease-out, top 0.1s ease-out;

    &-blank {
      height: 35px;
    }

    &-buttons {
      height: 45px;
      display: grid;
      padding: 0 2.5% 5px 2.5%;
      grid-template-columns: 45px 45px auto 45px 45px 45px;
      gap: 0 1vw;
      
      * {
        display: flex;
        justify-content: center;
        align-items: center;

        .v-btn {
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .slider {
    width: 95%;
    height: 20px;
    appearance: none;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    opacity: 1;
    position: fixed;
    bottom: 52.5px;
    transition: bottom 0.1s ease-out, width 0.1s ease-out, background 0.1s ease-out;
    direction: rtl;

    &-container {
      width: 100%;
      height: 35px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 30px;
      height: 20px;
      border-radius: 2px;
      background: #00d75b;
      cursor: pointer;
      transition: height 0.1s ease-out, box-shadow 0.1s ease-out;
    }
  }

  .atBottom {
    bottom: 0;
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, 0);
    direction: rtl;

    &::-webkit-slider-thumb {
      height: 2px;
      box-shadow: 200px 0 0 200px #00d75b;
    }
  }

  .hide_to_top {
    opacity: 0;
    top: -60px;
  }

  .hide_to_bottom {
    opacity: 0;
    bottom: -60px;
  }

  .hide {
    opacity: 0;
    top: 100vh;
  }
}
</style>
