<template>
  <div class="setting" @click.self="$parent.goSetting()">
    <div class="menus">
      <div class="menu" @click="none">
        <div class="name">정보 다시 읽어오기</div>
        <div class="icon">
          <v-icon icon="mdi-reload" color="grey" />
        </div>
      </div>
      <div class="menu" @click="goSource">
        <div class="name">원본 사이트로 이동</div>
        <div class="icon">
          <v-icon icon="mdi-open-in-new" />
        </div>
      </div>
      <div class="menu" @click="toggleAutoScroll">
        <div class="name">자동스크롤 기능</div>
        <div class="icon">
          <v-icon v-if="saved_filter.autoScroll" icon="mdi-toggle-switch" />
          <v-icon v-else icon="mdi-toggle-switch-off-outline" color="gray" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from "@/stores/toon";

import axios from "axios";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      none() {
        return;
      },
    }
  },
  computed: {
    ...mapState(useToonStore, ['saved_filter']),
  },
  methods: {
    ...mapActions(useToonStore, ['get_saved_title', 'set_add_Fav', 'set_remove_Fav']),
    goSource() {
      axios.get(`/api/toon/baseurl/`).then((res) => {
        var baseURL = res.data[0].url;

        axios
          .get(`/api/toon/article/info/${this.$route.params.submenu}/`)
          .then((res) => {
            var articleURL = res.data.href;
            window.open(baseURL + articleURL);
          });
      });
    },
    toggleAutoScroll() {
      console.log(this.saved_filter.autoScroll);

      var value;
      if (this.saved_filter.autoScroll) value = false;
      else value = true;

      axios.get(`/api/toon/filter/read/`).then((res) => {
        if (res.data.length > 0) {
          axios
            .put(`/api/toon/filter/update/${res.data[0].id}/`, {autoScroll: value}, {
              headers: {
                "X-CSRFTOKEN": Cookies.get("csrftoken"),
              },
            })
            .then(() => {
              this.saved_filter.autoScroll = value;
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          axios
            .post(`/api/toon/filter/create/`, {autoScroll: true}, {
              headers: {
                "X-CSRFTOKEN": Cookies.get("csrftoken"),
              },
            })
            .then(() => {
              this.saved_filter.autoScroll = value;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
  .setting {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.2);
    z-index: inherit;

    .menus {
      display: flex;
      flex-direction: column;
      gap: 1vh;

      .menu {
        width: 80vw;
        height: 6vh;
        display: flex;

        background: white;
        border-radius: 2vh;

        .name {
          width: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .icon {
          width: 20%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
</style>