<template>
  <div class="topbar" ref="topbar">
    <v-btn
      v-for="j in jaum"
      class="topbar-button pa-0"
      :class="{ active: isActive(j.route), fav: j.route == 'fav' }"
      ref="buttons"
      :key="j"
      variant="text"
      height="45px"
      @click="setTitle(j.route)"
    >
      <div class="topbar-button-text flex">{{ j.text }}</div>
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from "@/stores/toon";

export default {
  data() {
    return {
      jaum: [
        {
          "text": "★",
          "route": "fav",
        },
        {
          "text": "A",
          "route": "0",
        },
        {
          "text": "ㄱ",
          "route": "1",
        },
        {
          "text": "ㄴ",
          "route": "2",
        },
        {
          "text": "ㄷ",
          "route": "3",
        },
        {
          "text": "ㄹ",
          "route": "4",
        },
        {
          "text": "ㅁ",
          "route": "5",
        },
        {
          "text": "ㅂ",
          "route": "6",
        },
        {
          "text": "ㅅ",
          "route": "7",
        },
        {
          "text": "ㅇ",
          "route": "8",
        },
        {
          "text": "ㅈ",
          "route": "9",
        },
        {
          "text": "ㅊ",
          "route": "10",
        },
        {
          "text": "ㅋ",
          "route": "11",
        },
        {
          "text": "ㅌ",
          "route": "12",
        },
        {
          "text": "ㅍ",
          "route": "13",
        },
        {
          "text": "ㅎ",
          "route": "14",
        },
        {
          "text": "0-9",
          "route": "15",
        },
        {
          "text": "a-z",
          "route": "16",
        },
      ],
    }
  },
  computed: {
    ...mapState(useToonStore, ["saved_submenu"]),
  },
  updated() {
    if(this.$route.params.menu == "comic" && this.$route.params.submenu != "fav") {
      this.$refs.topbar.scrollTo({
        left: this.$refs.topbar.scrollLeft + this.$refs.buttons[parseInt(this.$route.params.submenu) + 1].$el.getBoundingClientRect().left - (this.$refs.topbar.clientWidth/2 - 17.5),
        top: 0,
        behavior: "smooth",
      });
    }
  },
  watch: {
    $route: function (to, from) {
      if (to.params.menu == "comic" && to.params.submenu !== from.params.submenu) {
        if(to.params.submenu != "fav") {
          this.$refs.topbar.scrollTo({
            left: this.$refs.topbar.scrollLeft + this.$refs.buttons[parseInt(to.params.submenu) + 1].$el.getBoundingClientRect().left - (this.$refs.topbar.clientWidth/2 - 17.5),
            top: 0,
            behavior: "smooth",
          });
        }
      }
    }
  },
  methods: {
    ...mapActions(useToonStore, ["set_saved_submenu"]),
    async setTitle(jaum) {
      if (jaum != this.saved_submenu) {
        this.$parent.titlelist = this.$parent.titlelist_filtered;
        this.set_saved_submenu(jaum);
        this.$router.push({ name: "main", params: {menu: "comic", submenu: jaum }});
        if (this.saved_submenu != "fav") {
          this.$refs.topbar.scrollTo({
            left: this.$refs.topbar.scrollLeft + this.$refs.buttons[parseInt(this.saved_submenu) + 1].$el.getBoundingClientRect().left - (this.$refs.topbar.clientWidth/2 - 17.5),
            top: 0,
            behavior: "smooth",
          });
        }
      } else {
        window.scrollTo({
          top: this.$parent.$parent.$refs.frametop.getBoundingClientRect().height, 
          left: 0, 
          behavior: 'smooth'
        });
      }
    },
    isActive(jaum) {
      if (this.saved_submenu == jaum) return true;
      else return false;
    }
  }
};
</script>

<style scoped lang="scss">
.topbar {
  width: 100%;
  display: flex;
  overflow: auto;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  -ms-overflow-style: none;
  &::-webkit-scrollbar{
    display:none;
  }

  &-button {
    &-text {
      width: 35px;
      height: 45px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .fav {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }

  .active {
    color: #00d75b;
    border-bottom: 2px solid #00d75b;
  }

  .v-btn {
    min-width: 0;
    border-radius: 0;
  }
}
</style>
