<template>
  <div class="bottombar">
    <v-btn
      v-for="(menu, i) in menus"
      class="bottombar-button"
      :key="menu"
      variant="text"
      width="25%"
      height="55px"
      :class="{ active: i == selected_menu }"
      @click="setTitle(menu.route, i)"
    >
      <div class="bottombar-button-text">
        <v-icon v-if="i == selected_menu">mdi-{{ menu.icon_selected }}</v-icon>
        <v-icon v-else>mdi-{{ menu.icon }}</v-icon>
      </div>
    </v-btn>
    <div class="slider"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from '@/stores/toon'

export default {
  data() {
    return {
      selected_menu: 0,
      menus: [
        {
          "icon": "cellphone",
          "icon_selected": "cellphone-text",
          "route": "wtoon",
        },
        {
          "icon": "book-open-outline",
          "icon_selected": "book-open",
          "route": "comic",
        },
        {
          "icon": "magnify",
          "icon_selected": "magnify-expand",
          "route": "search",
        },
        {
          "icon": "account-outline",
          "icon_selected": "account",
          "route": "userinfo",
        },
      ],
    }
  },
  computed: {
    ...mapState(useToonStore, ['saved_menu', 'saved_submenu', 'saved_keyword']),
  },
  watch: {
    $route: function (to, from) {
      if (to.params.menu !== from.params.menu) {
        if (this.$route.params.menu == "wtoon" || this.$route.params.menu == "w_list" || this.$route.params.menu == "w_view") this.selected_menu = 0;
        else if (this.$route.params.menu == "comic" || this.$route.params.menu == "c_list" || this.$route.params.menu == "c_view") this.selected_menu = 1;
        else if (this.$route.params.menu == "search") this.selected_menu = 2;
        else if (this.$route.params.menu == "userinfo") this.selected_menu = 3;
        else return false;
      }
    },
  },
  mounted() {
    if (this.$route.params.menu == "wtoon" || this.$route.params.menu == "w_list" || this.$route.params.menu == "w_view") this.selected_menu = 0;
    else if (this.$route.params.menu == "comic" || this.$route.params.menu == "c_list" || this.$route.params.menu == "c_view") this.selected_menu = 1;
    else if (this.$route.params.menu == "search") this.selected_menu = 2;
    else if (this.$route.params.menu == "userinfo") this.selected_menu = 3;
    else return false;
  },
  methods: {
    ...mapActions(useToonStore, ['set_saved_menu', 'set_saved_submenu']),
    setTitle(menu, index) {
      console.log(menu);
      this.set_saved_menu(menu);
      this.selected_menu = index;
      if (menu == "wtoon") { this.$router.push({ name: "main", params: { menu: menu, submenu: "fav" } });
      } else if (menu == "comic") this.$router.push({ name: 'main', params: { menu: menu, submenu: "fav"} });
      else if (menu == "search") this.$router.push({ name: 'main', params: {menu: menu, submenu: this.saved_keyword ? this.saved_keyword : "none"} });
      else if (menu == "userinfo") {
        this.$router.push({ name: 'main', params: { menu: 'userinfo', submenu: "all" }})
      }
    },
  }
};
</script>

<style scoped lang="scss">
.bottombar {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &-button {
    &-text {
      font-size: 16px;
      font-weight: bold;
    }

    &:first-child.active ~ .slider {
      transform: translateX(0) scaleX(0.25);
    }

    &:nth-child(2).active ~ .slider {
      transform: translateX(25%) scaleX(0.25);
    }

    &:nth-child(3).active ~ .slider {
      transform: translateX(50%) scaleX(0.25);
    }

    &:nth-child(4).active ~ .slider {
      transform: translateX(75%) scaleX(0.25);
    }
  }

  .slider {
    position: absolute;
    bottom: 53px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #00d75b;
    transform-origin: 0 0;
    transition: transform .25s;
  }

  .active {
    color: #00d75b;
  }

  .v-btn {
    min-width: 0;
    border-radius: 0;
  }
}
</style>
