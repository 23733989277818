<template>
  <div class="a_bookmark_header">
    a_bookmark_header
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from '@/stores/toon'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState(useToonStore, ['saved_title']),
  },
  created() {},
  methods: {
    ...mapActions(useToonStore, ['get_saved_title']),
  },
}
</script>

<style scoped lang="scss">
.a_bookmark_header {
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.05);
}
</style>
