<template>
  <div class="menu_filter" v-show="isOn">
    <div class="content flex">
      <div class="bg" :class="{ isOn: isLoaded }"  @click="hide"></div>
      <div class="box">
        <div class="filter-header">
          <div class="filter-header-text">
            <div class="white">장르</div>
            <div class="indigo" @click="select_All(1)">표시</div>
            <div class="red" @click="select_All(-1)">제외</div>
            <div class="gray-blue" @click="select_All(0)">무관</div>
          </div>
          <div class="filter-header-text">
            <div class="white">장르</div>
            <div class="indigo" @click="select_All(1)">표시</div>
            <div class="red" @click="select_All(-1)">제외</div>
            <div class="gray-blue" @click="select_All(0)">무관</div>
          </div>
        </div>
        <div class="filter-main">
          <div class="filter-main-select" v-for="filter in filters" :key="filter.id">
            <div class="filter-name flex">{{ filter.name }}</div>
            <div class="flex" @click="filter.status = 1">
              <v-icon color="indigo" v-if="filter.status == 1" icon="mdi-checkbox-marked"></v-icon>
              <v-icon color="indigo" v-else icon="mdi-checkbox-blank-outline"></v-icon>
            </div>
            <div class="flex" @click="filter.status = -1">
              <v-icon color="red" v-if="filter.status == -1" icon="mdi-checkbox-marked"></v-icon>
              <v-icon color="red" v-else icon="mdi-checkbox-blank-outline"></v-icon>
            </div>
            <div class="flex" @click="filter.status = 0">
              <v-icon color="#c1d2e6" v-if="filter.status == 0" icon="mdi-checkbox-marked"></v-icon>
              <v-icon color="#c1d2e6" v-else icon="mdi-checkbox-blank-outline"></v-icon>
            </div>
          </div>
        </div>
        <div class="filter-bottom flex">
          <v-btn class="filter-button" rounded="pill" block @click="apply">
            <div class="filter-button-text">적용</div>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";
import { useToonStore } from '@/stores/toon'
import { mapActions, mapState } from "pinia";

export default {
  data() {
    return {
      isOn: false,
      isLoaded: false,
      
      filter_inc: [],
      filter_exc: [],
      filter_none: [],
      filters: [
        { name: "학원", status: 1 },
        { name: "액션", status: 1 },
        { name: "SF", status: 1 },
        { name: "스토리", status: 1 },
        { name: "판타지", status: 1 },
        { name: "BL/백합", status: 1 },
        { name: "개그/코미디", status: 1 },
        { name: "연애/순정", status: 1 },
        { name: "드라마", status: 1 },
        { name: "로맨스", status: 1 },
        { name: "시대극", status: 1 },
        { name: "스포츠", status: 1 },
        { name: "일상", status: 1 },
        { name: "추리/미스터리", status: 1 },
        { name: "공포/스릴러", status: 1 },
        { name: "성인", status: 1 },
        { name: "옴니버스", status: 1 },
        { name: "에피소드", status: 1 },
        { name: "무협", status: 1 },
        { name: "소년", status: 1 },
        { name: "기타", status: 1 },
      ],
    }
  },
  computed: {
    ...mapState(useToonStore, ['saved_filter']),
  },
  methods: {
    ...mapActions(useToonStore, ['set_saved_filter']),
    show() {
      this.isOn = true;
    },
    hide() {
      this.isLoaded = false;
    },
    select_All(filter) {
      for (var f in this.filters) {
        this.filters[f].status = filter;
      }
    },
    async apply() {
      await this.save_filter();
      this.hide();
    },
    async save_filter() {
      var data = {
        school: this.filters[0].status,
        action: this.filters[1].status,
        SF: this.filters[2].status,
        story: this.filters[3].status,
        fantasy: this.filters[4].status,
        bl_les: this.filters[5].status,
        gag: this.filters[6].status,
        purelove: this.filters[7].status,
        drama: this.filters[8].status,
        romance: this.filters[9].status,
        history: this.filters[10].status,
        sports: this.filters[11].status,
        life: this.filters[12].status,
        mystery: this.filters[13].status,
        thriller: this.filters[14].status,
        adult: this.filters[15].status,
        omni: this.filters[16].status,
        episode: this.filters[17].status,
        martial: this.filters[18].status,
        boy: this.filters[19].status,
        etc: this.filters[20].status,
      };

      await axios.get(`/api/toon/filter/read/`).then((res) => {
        if (res.data.length > 0) {
          axios
            .put(`/api/toon/filter/update/${res.data[0].id}/`, data, {
              headers: {
                "X-CSRFTOKEN": Cookies.get("csrftoken"),
              },
            })
            .then((res) => console.log(res))
            .catch((err) => {
              console.log(err);
            });
        } else {
          axios
            .post(`/api/toon/filter/create/`, data, {
              headers: {
                "X-CSRFTOKEN": Cookies.get("csrftoken"),
              },
            })
            .then((res) => console.log(res))
            .catch((err) => {
              console.log(err);
            });
        }
      });

      var data_for_save = {};
      for (var f in this.filters) {
        data_for_save[this.filters[f].name] = this.filters[f].status;
      }
      this.set_saved_filter(data_for_save);
    }
  },
  watch: {
    isOn: async function (val) {
      if (val == true) {
        setTimeout(() => {
          this.isLoaded = true;
        }, 1);
      }
    },
    isLoaded: function (val) {
      if (val == false) {
        setTimeout(() => {
          this.isOn = false;
        }, 100);
      }
    },
    saved_filter: function (val) {
      var filters_temp = [];
      for (var f in Object.keys(val)) {
        var filter = {};
        if (Object.keys(val)[f] != "autoScroll") {
          filter["name"] = Object.keys(val)[f]
          filter["status"] = val[Object.keys(val)[f]];
          filters_temp.push(filter);
        }
      }
      this.filters = filters_temp;
    }
  }
}
</script>

<style scoped lang="scss">
.menu_filter {
  width: 100%;
  height: 100%;

  .content {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      
      background: rgba(0, 0, 0, 0.85);
      opacity: 0;
      transition: opacity 0.1s linear;
    }

    .box {
      width: 90%;
      height: 55%;
      border-radius: 5px;
      z-index: 0;

      .filter-header {
        width: 100%;
        height: 36px;
        display: grid;
        grid-template-columns: 48.5% 48.5%;
        gap: 0.5vh 3%;
        padding: 0 5px 0 5px;
        margin-bottom: 10px;
        border-radius: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(0, 0, 0, 0.2);
        color: white;

        .filter-header-text {
          display: grid;
          grid-template-columns: auto 35px 35px 35px;
          font-weight: bold;

          .indigo {
            color: #3f51b5;
          }

          .red {
            color: #f44336;
          }

          .gray-blue {
            color: #c1d2e6;
          }

          * {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .filter-main {
        height: calc(100% - 72px);
        display: grid;
        grid-template-columns: 48.5% 48.5%;
        gap: 0.5vh 3%;
        padding: 0 5px 0 5px;

        &-select {
          display: grid;
          grid-template-columns: auto 35px 35px 35px;

          .filter-name {
            color: white;
            font-size: 12px;
          }
        }
      }

      .filter-bottom {
        height: 60px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .isOn {
      opacity: 1;
    }
  }
}
</style>