<template>
  <div class="account_header flex">
    <div class="page_name flex">유저정보</div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.account_header {
  width: 100%;
  height: 100%;

  background: white;
}
</style>
