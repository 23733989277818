<template>
  <div class="articlelist">
    <div class="titleinfo">
      <div class="titleinfo-name">
        {{ saved_title.name }}
      </div>
      <div class="titleinfo-summary" @click="expandSummary">
        <div class="titleinfo-summary-text" :class="{ expanded: isExpanded }">
          {{  saved_title.summary }}
        </div>
        <div class="titleinfo-summary-button flex">
          <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </div>
      </div>
    </div>
    <div class="articles" v-for="(article, i) in articlelist" :key="article.id" @touchstart.passive="touchStartMethod($event)" @touchend.passive="touchEndMethod($event, i)">
      <div @click="show_Imgs(article)">
        <div
          class="articles-background"
          v-bind:style="{
            'width': article.readinfo + '%',
          }"
        >
        </div>
        <div class="articles-name">{{ article.no }}. {{ article.article_name }}</div>
        <div class="articles-updatedate">{{ formatDate(article.updatedate) }}</div>
      </div>
      <Transition>
        <div class="articles-swipemenu flex" v-if="i == touchedIndex" @click="touchedIndex = null">
          <v-btn v-if="article.readinfo < 95" class="mr-3" color="#17ce5f" width="35%" @click="addReadInfo(article)"><div class="articles-swipemenu-text-1">읽음</div></v-btn>
          <v-btn v-else color="error" width="35%" @click="removeReadInfo(article)"><div class="articles-swipemenu-text-1">읽지 않음</div></v-btn>
        </div>
      </Transition>
    </div>
    <VueEternalLoading v-if="this.saved_title.isLoad_title" :load="get_Articles" v-model:is-initial="isInitial">
      <template #loading>
          <b></b>
      </template>
      <template #no-more>
          <b></b>
      </template>
    </VueEternalLoading>
    <div v-if="isLoading" class="bg flex">
      <div class="loader"></div>
    </div>
    <div class="quickbar">
      <div v-if="readInfo.no == 1 && articlelist.length > 0" class="show_first" @click="show_Imgs(readInfo)">
        <div class="show_first-thumbnail flex">
          <div class="show_first-thumbnail-frame"><img :src="`${saved_title.thumbnail.includes('https://') ? '' : baseURL}${saved_title.thumbnail}`" /></div>
        </div>
        <div class="show_first-name flex">
          <div class="show_first-name-text">첫화보기 - {{ readInfo.article_name }}</div>
        </div>
        <div class="show_first-button flex"><v-icon icon="mdi-chevron-right" color="white"></v-icon></div>
      </div>
      <div v-else-if="readInfo.no > 0 && articlelist.length > 0" class="show_latest" @click="show_Imgs(readInfo)">
        <div class="show_first-thumbnail flex">
          <div class="show_first-thumbnail-frame"><img :src="`${saved_title.thumbnail.includes('https://') ? '' : baseURL}${saved_title.thumbnail}`" /></div>
        </div>
        <div class="show_first-name flex">
          <div class="show_first-name-text">이어보기 - {{ readInfo.article_name }}</div>
        </div>
        <div class="show_first-button flex"><v-icon icon="mdi-chevron-right" color="white"></v-icon></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from "@/stores/toon";
import axios from "axios";
import Cookies from "js-cookie";
import { nextTick } from "vue";
import { VueEternalLoading } from "@ts-pro/vue-eternal-loading";

export default {
  components: {
    VueEternalLoading,
  },
  data() {
    return {
      page: 0,
      articlelist: [],
      readInfo: {},

      isLoading: true,
      isInitial: false,
      isExpanded: false,

      touchStartX: null,
      touchStartY: null,
      touchedIndex: null,
    }
  },
  computed: {
    ...mapState(useToonStore, ['baseURL', 'saved_title', 'saved_article', 'saved_scroll_article']),
  },
  watch: {
    $route: async function (to, from) {
      if (to.params.menu != "w_list") {
        if (from.params.menu == "w_list") this.set_saved_scroll_article(window.scrollY);
      } else if ((from.params.menu == "wtoon" || from.params.menu == "w_view") && this.saved_scroll_article != null) {
        await nextTick();
        window.scrollTo(0, this.saved_scroll_article);
      } else if (from.params.menu == to.params.menu && from.params.submenu !== to.params.submenu)  {
        return;
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  activated() {
    window.scrollTo(0, 0);
    if (this.saved_title.isLoad_titlelist == false) {
      this.page = 0;
      this.articlelist = [];
      this.isInitial = true;
      this.get_ReadInfos();
    } else {
      setTimeout(() => {
        this.get_Articles_update();
      }, 100)
    }
  },
  methods: {
    ...mapActions(useToonStore, ['set_saved_scroll_article', 'set_saved_scroll_img']),
    async get_Articles(action) {
      this.isLoading = true;
      
      this.page += 1;
      console.log(`get_Articles of ${this.saved_title.name} at page ${this.page}`);
      await axios
        .get(`/api/toon/article/list/${this.$route.params.submenu}/${this.page}/`)
        .then(({ data }) => {
          if (data) {
            if (this.page == 1) this.saved_title.isLoad_titlelist = true;
            this.articlelist.push(...data);
            action.loaded(data.length, 100);
          }
        })
        .catch((err) => console.log(err));
      
      this.isLoading = false;
    },
    get_Articles_update() {
      console.log(`get_Articles_update of ${this.saved_title.name} at page ${this.page}`);

      axios
        .get(`/api/toon/article/list/${this.$route.params.submenu}/1/${this.page}/`)
        .then(({ data }) => {
          if (data) {
            this.articlelist = data;
          }
        })
        .catch((err) => console.log(err));
      
      this.get_ReadInfos();
    },
    get_ReadInfos() {
      axios
        .get(`/api/toon/readinfo/list/${this.$route.params.submenu}/`)
        .then(({ data }) => {
          if (data) {
            this.readInfo = data[0];
          }
        })
        .catch((err) => console.log(err));
    },
    expandSummary() {
      this.isExpanded = !this.isExpanded;
    },
    show_Imgs(article) {
      this.touchedIndex = null;
      this.saved_article.isLoad = false;
      this.set_saved_scroll_img(null);
      this.$router.push({ name: 'main', params: { menu: 'w_view', submenu: article.id  }});
    },
    formatDate(date) {
      return `${date.substr(2, 2)}.${date.substr(5, 2)}.${date.substr(8, 2)}`
    },
    touchStartMethod(touch) {
      this.touchStartX = touch.touches[0].clientX;
      this.touchStartY = touch.touches[0].clientY;
    },
    touchEndMethod(touch, index) {
      if (Math.abs(touch.changedTouches[0].clientX - this.touchStartX) > 20 && Math.abs(touch.changedTouches[0].clientY - this.touchStartY) < 60) {
        this.touchedIndex = index;
        window.addEventListener("scroll", this.handleScroll);
      }
    },
    addReadInfo(article) {
      var data = {
        title: this.saved_title.id,
        article: article.id,
        completion: 100,
      };

      axios
        .post(`/api/toon/readinfo/create/`, data, {
          headers: {
            "X-CSRFTOKEN": Cookies.get("csrftoken"),
          },
        })
        .then((res) => {
          console.log(res.data);
          article.readinfo = 100;
          article.readinfoId = res.data.id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeReadInfo(article) {
      var data = {
        title: this.saved_title.id,
        article: article.id,
        completion: 0,
      };

      axios
        .put(`/api/toon/readinfo/update/${article.readinfoId}/`, data, {
          headers: {
            "X-CSRFTOKEN": Cookies.get("csrftoken"),
          },
        })
        .then(() => { article.readinfo = 0; })
        .catch((err) => {
          console.log(err);
        });
    },
    handleScroll() {
      this.touchedIndex = null;
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>

<style scoped lang="scss">
.articlelist {
  margin-bottom: 50px;

  .titleinfo {
    padding: 0 10px 10px 10px;
    border-bottom: 1px solid gray;

    &-name {
      font-weight: bold;
    }

    &-summary {
      display: flex;
      font-size: 12px;
      color: gray;

      &-text {
        width: calc(100% - 25px);
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 20px;
        transition: max-height 0.5s ease-out;
      }

      &-button {
        width: 25px;

        align-items: flex-start;
      }
      .expanded {
        max-height: 200px;
      }
    }
  }

  .articles {
    height: 47px;
    position: relative;
    padding: 5px 10px 5px 10px;
    border-bottom: 1px solid gray;

    &-background {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(32, 94, 143, 0.1);
      height: 100%;
    }

    &-name {
      font-size: 12px;
      justify-content: start;
    }

    &-updatedate {
      font-size: 10px;
      color: gray;
      text-align: right;
    }

    &-swipemenu {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
      
      background: rgba(0, 0, 0, 0.2);

      &-text-1 {
        color: white;
        font-weight: bold;
      }
    }

    .v-enter-active,
    .v-leave-active {
      transition: opacity 0.25s ease;
    }

    .v-enter-from,
    .v-leave-to {
      opacity: 0;
    }
  }

  .bg {
    width: 100vw;
    height: 100vh;
  
    position: fixed;
    top: 0;
    left: 0;
  
    background: rgba(0, 0, 0, 0.4);
  }

  .quickbar {
    width: 100%;
    position: sticky;
    bottom: 56px;

    .show_first,
    .show_latest {
      width: 100%;
      height: 5 0px;
      
      display: grid;
      grid-template-columns: 60px auto 40px;

      background: #205e8f;

      &-thumbnail {
        &-frame {
          width: 80%;
          height: 32px;
          border-radius: 5px;
          overflow: hidden;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      &-name {
        width: 100%;
        overflow: hidden;

        &-text {
          width: 100%;

          color: white;
          font-size: 13px;
          font-weight: bold;
  
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
