import { createRouter, createWebHistory } from "vue-router";
import home from "../views/0_home.vue";
import axios from "axios";

const requireAuth = () => (to, from, next) => {
  axios
    .get('/api/user/user/')
    .then(() => {
      next();
    })
    .catch(() => {
      router.push({
        name: "login",
        query: { redirect: to.href },
      });
    })
}

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: home,
      beforeEnter: requireAuth(),
    },
    {
      path: "/:menu/:submenu",
      name: "main",
      component: home,
      beforeEnter: requireAuth(),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/1_LogIn.vue"),
    },
    {
      path: "/signup",
      name: "signup",
      component: () => import("../views/2_SignUp.vue"),
    },
  ],
});

export default router;
