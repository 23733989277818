<template>
  <div class="search">
    <div class="header">
      <div v-if="saved_searchResult.length > 0" class="header-wtoon">
        <div class="header-wtoon-bg">
          웹툰
        </div>
      </div>
      <div v-if="saved_searchResult_comic.length > 0" class="header-comic">
        <div class="header-comic-bg">
          코믹
        </div>
      </div>
    </div>
    <div class="result">
      <div class="result-wtoon">
        <div class="result-wtoon-item" v-for="result in saved_searchResult" :key="result.id" @click="show_Wtoon(result)">
          <div class="thumbnail">
            <img :src="`${result.thumbnail}`" />
          </div>
          <div class="name">{{ result.name }}</div>
        </div>
      </div>
      <div class="result-comic">
        <div class="result-comic-item" v-for="result_comic in saved_searchResult_comic" :key="result_comic.id" @click="show_Comic(result_comic)">
          <div class="thumbnail">
            <img :src="`${result_comic.thumbnail.includes('https://') ? '' : baseURL_comic}${result_comic.thumbnail}`" />
          </div>
          <div class="name">{{ result_comic.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from "@/stores/toon";
import { nextTick } from "vue";

export default {
  components: {
  },
  data() {
    return {}
  },
  async mounted() {
    if (this.saved_menu == "") await this.set_saved_menu(this.$route.params.menu);
    if (this.saved_scroll_search) {
      console.log(this.saved_scroll_search);
      await nextTick();
      window.scrollTo(0, this.saved_scroll_search);
    }
  },
  computed: {
    ...mapState(useToonStore, ['baseURL_comic', 'saved_searchResult', 'saved_searchResult_comic', 'saved_scroll_search']),
  },
  methods: {
    ...mapActions(useToonStore, ['set_saved_scroll_search', 'set_saved_title']),
    async show_Wtoon(title) {
      await this.set_saved_title(title);
      await this.set_saved_scroll_search(window.scrollY);
      this.$router.push({ name: 'main', params: { menu: 'w_list', submenu: title.id }});
    },
    async show_Comic(title) { 
      await this.set_saved_title(title);
      await this.set_saved_scroll_search(window.scrollY);
      this.$router.push({ name: 'main', params: { menu: 'c_list', submenu: title.id }});
    },
  },
}
</script>

<style scoped lang="scss">
.result {
  width: 100%;
  min-height: calc(80vh - 55px);
  
  margin-bottom: 55px;
  display: grid;
  grid-template-columns: 49% 49%;
  grid-template-rows: fit-content(5%);
  gap: 2%;

  &-wtoon,
  &-comic {
    width: 100%;
    min-height: 100%;
    
    display: grid;
    gap: 1vh 2%;

    &-item {
      position: relative;

      .thumbnail {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        position: relative;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .name {
        position: absolute;
        right: 5px;
        bottom: 0;

        color: white;
        font-weight: bold;
        text-shadow: 1px 1px 3px #000000, 1px -1px 3px #000000, -1px 1px 3px #000000, -1px -1px 3px #000000;
      }
    }
  }
}

.header {
  height: 100%;
  min-height: 0;
  margin-bottom: 0;

  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
  position: sticky;
  top: 45px;
  z-index: 1;

  &-wtoon,
  &-comic {
    width: 100%;
    height: 100%; 
    display: flex;
    justify-content: center;

    &-bg {
      width: 100%;
      height: 100%;
      color: black;
      background: white;
      text-align: center;
      font-weight: bold;
    }
  }

}
</style>