<template>
  <div class="titleinfo">
    <div class="top-decoration"
      :class="{ show: lastScrollPosition == 0 }"
      v-bind:style="{
        'opacity': 100 - lastScrollPosition * 2 + '%',
      }"
    ></div>
    <div
      class="topbar"
      :class="{ transparent: lastScrollPosition < 10 }"
      v-bind:style="{
        'opacity': lastScrollPosition < 10 ? '100%' : lastScrollPosition - 10 + '%',
      }"
    >
      <div class="button-back flex">
        <v-btn variant="text" height="45px" block @click="goBack"><v-icon icon="mdi-chevron-left" size="x-large"></v-icon></v-btn>
      </div>
      <div class="title-name-top flex" :class="{ show: showName }">
        {{ saved_title.name }}
      </div>
      <div class="fav-icon flex">
        <v-btn variant="text" height="45px" block>
          <v-icon v-if="saved_title.refreshing" icon="mdi-timer-sand-empty" @click="toggleRefresh"></v-icon>
          <v-icon v-else icon="mdi-update" @click="toggleRefresh"></v-icon>
        </v-btn>
      </div>
      <div class="fav-icon flex">
        <v-btn variant="text" width="45px" height="45px" block>
          <v-icon v-if="saved_title.isFav == true" width="100%" icon="mdi-heart" color="red" @click="remove_Fav"></v-icon>
          <v-icon v-else icon="mdi-heart-outline" width="100%" @click="add_Fav"></v-icon>
        </v-btn>
      </div>
    </div>
    <div class="title-thumbnail flex" ref="thumbnail">
      <div class="title-thumbnail-box">
        <img :src="`${saved_title.thumbnail.includes('https://') ? '' : baseURL_comic}${saved_title.thumbnail}`" />
        <div class="title-genre">
          <div v-for="g in return_Genre(saved_title.genre)" :key="g.id" class="title-genre-badge flex">
              {{ g }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="isOn_refresh" class="bg flex" @click.self="toggleRefresh">
      <div class="refresh flex">
        <div class="refresh-box flex">
          <div class="refresh-button flex">
            <span v-if="!saved_title.refreshing" class="mdi mdi-update" @click="exeRefresh" />
            <div class="refreshStatus" v-else>
              <div class="refreshStatus-top">{{ refreshStatus.status }}</div>
              <div class="refreshStatus-bottom">
                <div class="refreshStatus-bottom-left">
                  <div class="flex">총 {{ refreshStatus.total }}</div>
                  <div class="flex">○ {{ refreshStatus.success }}</div>
                  <div class="flex">× {{ refreshStatus.failure }}</div>
                </div>
                <span class="mdi mdi-reload flex" @click="checkRefresh" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from "@/stores/toon";
import axios from "axios";

export default {
  data() {
    return {
      lastScrollPosition: 0,
      showName: false,

      isOn_refresh: false,
      refreshStatus: {
        status: 'pending',
        total: '-',
        success: '-',
        failure: '-',
      },
    }
  },
  computed: {
    ...mapState(useToonStore, ['baseURL_comic', 'saved_title', 'saved_keyword', 'saved_menu', 'saved_submenu']),
  },
  created() {
    if (this.saved_title.isLoad_title == false) {
      this.get_saved_title_comic(this.$route.params.submenu);
    }
  },
  methods: {
    ...mapActions(useToonStore, ['get_saved_title_comic', 'set_add_Fav_comic', 'set_remove_Fav_comic']),
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      this.lastScrollPosition = currentScrollPosition

      if (this.$refs.thumbnail.getBoundingClientRect().height + this.$refs.thumbnail.getBoundingClientRect().y < 10) this.showName = true;
      else this.showName = false;

      console.log(this.lastScrollPosition);
    },
    goBack() {
      if (this.saved_menu == "comic" || this.saved_menu == null) {
        this.$router.push({ name: "main", params: { menu: "comic", submenu: (this.saved_submenu ? this.saved_submenu : "fav") } });
        if (this.saved_submenu == "fav") this.$parent.$refs.comic.get_Titles_update_bookmark();
      } else if (this.saved_menu == "search") {
        this.$router.push({ name: "main", params: { menu: this.saved_menu, submenu: this.saved_keyword } });
      }
    },
    toggleRefresh() {
      this.isOn_refresh = !this.isOn_refresh;
    },
    exeRefresh() {
      this.saved_title.refreshing = true;

      axios
        .get(`/api/toon/refresh/title/${this.$route.params.submenu}/`)
        .then((res) => {
          this.saved_title.refresh_task = res.data.celery_task;
          this.isOn_refresh = false;
        })
        .catch((err) => {
          console.log(err);
        })
    },
    checkRefresh() {
      axios
        .get(`/api/toon/refresh/status/${this.saved_title.refresh_task}/`)
        .then((res) => {
          console.log(res.data)
          this.refreshStatus.status = res.data.state;
          this.refreshStatus.total = res.data.details.total;
          this.refreshStatus.success = res.data.details.success;
          this.refreshStatus.failure = res.data.details.failure;
        })
        .catch((err) => {
          console.log(err);
        })
    },
    async add_Fav() {
      await this.set_add_Fav_comic();
      if (this.$parent.$refs.comic.titlelist.length > 0) {
        for (var t in this.$parent.$refs.comic.titlelist) {
          if (this.$parent.$refs.comic.titlelist[t].id == this.saved_title.id) {
            this.$parent.$refs.comic.titlelist[t].isFav = true;
            break;
          }
        }
      }
      if (this.saved_title.isFav == false) this.saved_title.isFav = true;
    },
    async remove_Fav() {
      await this.set_remove_Fav_comic();
      if (this.$parent.$refs.comic.titlelist.length > 0) {
        for (var t in this.$parent.$refs.comic.titlelist) {
          if (this.$parent.$refs.comic.titlelist[t].id == this.saved_title.id) {
            this.$parent.$refs.comic.titlelist[t].isFav = false;
            break;
          }
        }
      }
      if (this.saved_title.isFav) this.saved_title.isFav = false;
    },
    return_Genre(value) {
      if (value == null) {
        return [];
      } else {
        var genre_raw = value.split(',')
        var genre = [];
        for (var g in genre_raw) {
          genre.push(genre_raw[g].replace("[", "").replace("]", '').replaceAll("'", "").trim());
        }
        return genre;
      }
    },
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style scoped lang="scss">
.titleinfo {
  width: 100%;
  height: 100%;

  position: relative;

  background: white;

  .topbar {
    width: 100%;
    height: 45px;

    position: fixed;
    top: 0;
    display: grid;
    grid-template-columns: 45px auto 45px 45px;
    
    z-index: 1;

    background: rgb(255, 255, 255);
    border-bottom: 0.1px solid gray;

    font-size: 18px;
    font-weight: normal;
    color: black;

    .title-name-top {
      justify-content: start;
      overflow: auto;
      color: black;
      font-weight: bold;
      opacity: 0;
    }

    .fav-icon {
      font-size: 13px;

      .isFav {
        color: red;
      }
    }

    .show {
      opacity: 100%;
    }
  }

  .top-decoration {
    position: absolute;
    top: 0;
    
    width: 100%;
    height: 40%;

    background: #205e8f;
    opacity: 0;
  }

  .title-thumbnail {
    width: 100%;
    height: calc(100% - 25px);
    position: absolute;
    top: 25px;

    &-box {
      position: relative;
      width: 35vw;
      height: 55vw;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .title-genre {
        position: absolute;
        display: flex;
        bottom: 2px;
        right: 2px;

        &-badge {
          margin: 2px;
          padding: 1px 3px 1px 3px;
          border-radius: 4px;
          background: #205e8f;
          color: white;
          font-size: 11px;
        }
      }
    }
  }

  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;

    .refresh {
      width: 120px;
      height: 120px;

      &-button {
        width: 100px;
        height: 100px;
        border: 1px solid gray;
        border-radius: 10px;

        &:active {
          background: rgba(0, 0, 0, 0.1);
        }

        .mdi-update {
          font-size: 50px;
        }

        .mdi-reload {
          font-size: 25px;
        }

        .refreshStatus {
          width: 100%;
          height: 100%;
          display: grid;
          grid-template-rows: 25px 75px;

          &-top {
            text-align: center;
          }

          &-bottom {
            display: grid;
            grid-template-columns: repeat(2, 50%);

            &-left {
              display: grid;
              grid-template-rows: repeat(3, calc(100%/3));
              font-size: 13px;
            }
          }
        }
      }

      &-box {
        width: 95%;
        height: 95%;
        border-radius: 10px;
        background: white;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
          0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      }
    }
  }

  .transparent {
    border-bottom: 0px;
    background: rgba(255, 255, 255, 0);
    color: white;
  }

  .show {
    opacity: 100%;
  }
}
</style>

