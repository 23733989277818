<template>
  <div class="search_header" ref="search_header">
    <div
      class="topbar"
      ref="topbar"
      v-bind:style="{
        'borderBottom': `1px solid rgba(232, 232, 232, ${topbarBorder})`,
      }"
    >
      <div v-if="showKeyword" class="menu-title flex" @click="goTop">{{ saved_keyword }}</div>
      <div v-else class="menu-title flex">검색</div>
    </div>
    <div class="search-box flex" ref="thumbnail">
      <div class="search">
        <v-text-field
          width="80vw"
          v-model="keyword"
          append-icon="mdi-magnify"
          ref="search"
          label="제목"
          variant="outlined"
          clearable
          hide-details
          @keyup.enter="exeSearch"
          @click:append="exeSearch"
        >
        </v-text-field>
      </div>
    </div>
    <div v-show="isLoading" class="bg flex">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from '@/stores/toon'

export default {
  data() {
    return {
      lastScrollPosition: 0,
      topbarBorder: 0,
      showKeyword: false,
      
      keyword: null,

      isLoading: false,
    }
  },
  async mounted() {
    window.addEventListener('scroll', this.onScroll);

    if (this.$route.params.submenu != "none") {
      this.keyword = this.$route.params.submenu;
      this.set_saved_keyword(this.keyword);
      if (this.saved_searchResult.length == 0 && this.saved_searchResult_comic.length == 0) {
        this.isLoading = true;
        await this.get_searchResult(this.saved_keyword);
        this.isLoading = false;
      }
    } else {
      this.$refs.search.focus();
    }
  },
  beforeUnmount () {
    this.set_saved_scroll_search(this.lastScrollPosition);
    window.removeEventListener('scroll', this.onScroll)
  },
  computed: {
    ...mapState(useToonStore, ['saved_keyword', 'saved_searchResult', 'saved_searchResult_comic']),
  },
  watch: {
    $route: async function (to, from) {
      if (from.params.menu == to.params.menu && from.params.submenu !== to.params.submenu && this.$route.params.submenu != "none") {
        this.keyword = this.$route.params.submenu;

        if (this.saved_keyword != this.$route.params.submenu) {
          this.set_saved_keyword(this.keyword);
          this.isLoading = true;
          await this.get_searchResult(this.saved_keyword);
          this.isLoading = false;
        }
      }
    }
  },
  methods: {
    ...mapActions(useToonStore, ['set_saved_keyword', 'get_searchResult', 'set_saved_scroll_search']),
    goTop() {
      window.scrollTo(0, 0);
      this.keyword = null;
      this.$refs.search.focus();
    },
    async exeSearch() {
      this.set_saved_keyword(this.keyword);

      if (this.saved_keyword) {
        this.isLoading = true;
        this.$router.push({ name: 'main', params: { menu: 'search', submenu: this.saved_keyword }});
        await this.get_searchResult(this.saved_keyword);
        this.isLoading = false;
        this.$refs.topbar.focus();
      } else {
        this.$refs.search.focus();
      }
    },
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      this.lastScrollPosition = currentScrollPosition
      this.topbarBorder = Math.min(currentScrollPosition / (this.$refs.search_header.getBoundingClientRect().height - 55), 0.99);

      if (this.$refs.search.getBoundingClientRect().y <= 0) this.showKeyword = true;
      else this.showKeyword = false;
    }
  },
}
</script>

<style scoped lang="scss">
.search_header {
  width: 100%;
  height: 100%;
  position: relative;

  background: white;

  .topbar {
    width: 100%;
    height: 45px;

    position: fixed;
    top: 0;
    display: flex;

    z-index: 1;

    background: white;
    font-size: 18px;
    font-weight: normal;
    color: black;

    .menu-title {
      width: 100%;
      
      font-weight: bold;
    }
  }

  .search-box {
    width: 100%;
    height: calc(100% - 45px);
    position: absolute;
    top: 45px;

    .search {
      width: 80%;
    }
  }

  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
