<template>
  <div class="wtoon_header">
    <div class="chart-title flex">
      <div>TOP</div>
      <div class="filter-icon flex" @click="show_menu_filter">
        <v-btn variant="text" height="45px" block>
          <v-icon icon="mdi-filter" color="white"></v-icon>
        </v-btn>
      </div>
    </div>
    <div class="chart-type flex">
      <div class="chart-type-button flex" :class="{selected : chart.selected}" v-for="(chart, i) in charts" :key="chart.id" @click="setChart(i)">{{ chart.name }}</div>
      <div class="bg-button"></div>
    </div>
    <div class="chart-list" v-for="(chart, chart_i) in charts" :key="chart.id">
      <Transition>
        <div class="chart-container" v-show="chart.selected" ref="chart" @touchstart.passive="touchStartMethod($event, chart_i)" @touchend.passive="touchEndMethod($event, chart_i)">
          <div class="chart-items" v-for="(c, i) in chart.chart" :key="c.id" @click="show_Articles(c)">
            <img class="chart-items-img" :src="`${c.thumbnail}`" />
            <div class="chart-items-rank flex">
              <div class="chart-items-rank-text flex">{{ i + 1 }}</div>
            </div>
            <div class="chart-items-title flex">
              <div class="chart-items-title-text flex">{{ c.name }}</div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <menu_filter ref="menu_filter" />
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useToonStore } from '@/stores/toon'
import menu_filter from './3_menu_filter.vue'
import axios from "axios";
import { nextTick } from "vue";

export default {
  components: {
    menu_filter,
  },
  data() {
    return {
      charts : [
        {name: "실시간", selected: 1, period: "realtime", chart: []},
        {name: "일간", selected: 0, period: "day", chart: []},
        {name: "주간", selected: 0, period: "week", chart: []},
        {name: "월간", selected: 0, period: "month", chart: []},
      ],
      touchStartX: null,
      touchStartY: null,
      startScrollX: null,
    }
  },
  computed: {
    ...mapState(useToonStore, ['saved_title']),
  },
  created() {
    for (var chart in this.charts) {
      this.get_Chart(this.charts[chart]);
    }
  },
  methods: {
    ...mapActions(useToonStore, ['set_saved_title', 'set_saved_scroll_title']),
    show_menu_filter() {
      this.$refs.menu_filter.show();
    },
    async setChart(index) {
      for (var i in this.charts) {
        if (i == index) this.charts[i].selected = 1;
        else this.charts[i].selected = 0;
      }
      await nextTick();
      this.$refs.chart[index].scrollTo(0, 0);
      this.startScrollX = 0;
    },
    get_Chart(chart) {
      axios
        .get(`/api/toon/chart/${chart.period}/`)
        .then((res) => {
          chart.chart = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async show_Articles(title) {
      if (title.title) title.id = title.title;
      await this.set_saved_title(title);
      await this.set_saved_scroll_title(window.scrollY);
      if (this.saved_submenu == "fav" && title.readinfoId) {
        this.saved_article.isLoad = false;
        this.$router.push({ name: 'main', params: { menu: 'w_view', submenu: title.readinfoId  }});
      } else {
        this.$router.push({ name: 'main', params: { menu: 'w_list', submenu: title.id  }});
      }
    },
    touchStartMethod(touch, index) {
      this.touchStartX = touch.touches[0].clientX;
      this.touchStartY = touch.touches[0].clientY;
      this.startScrollX = this.$refs.chart[index].scrollLeft;
    },
    touchEndMethod(touch, index) {
      if (
        touch.changedTouches[0].clientX - this.touchStartX < -100 &&
        Math.abs(touch.changedTouches[0].clientY - this.touchStartY) < 100 &&
        this.$refs.chart[index].scrollWidth - window.innerWidth <= this.startScrollX + 5
      ) {
        if (index < 3) this.setChart(index + 1);
      } else if (
        touch.changedTouches[0].clientX - this.touchStartX > 100 &&
        Math.abs(touch.changedTouches[0].clientY - this.touchStartY) < 100 &&
        this.startScrollX == 0
      ) {
        if (index > 0) this.setChart(index - 1);
      }
    },
  },
}
</script>

<style scoped lang="scss">
.wtoon_header {
  width: 100%;
  height: 100%;
  position: relative;

  background: rgba(0, 0, 0, 1);

  .chart-title {
    width: 100%;
    height: 45px;
    color: white;
    font-weight: bold;
    font-size: 18px;

    .filter-icon {
      width: 45px;
      height: 45px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .chart-type {
    width: calc(100% - 10px);
    height: 35px;
    position: relative;
    display: flex;
    margin: 0 3px 0 5px;

    &-button {
      width: 25%;
      height: 29px;
      mix-blend-mode: difference;
      z-index: 1;

      color: white;
      font-weight: bold;

      &:first-child.selected ~ .bg-button {
        transform: translateX(0) scaleX(0.25);
      }
      
      &:nth-child(2).selected ~ .bg-button {
        transform: translateX(25%) scaleX(0.25);
      }

      &:nth-child(3).selected ~ .bg-button {
        transform: translateX(50%) scaleX(0.25);
      }

      &:nth-child(4).selected ~ .bg-button {
        transform: translateX(75%) scaleX(0.25);
      }
    }

    .bg-button {
      width: 100%;
      height: 26px;
      position: absolute;
      top: 5px;
      left: 0;
      border-radius: 15%/50%;
      background-color: white;
      transform-origin: 0 0;
      transition: transform .25s;
    }
  }

  .chart-list {
    height: calc(100% -  80px);
    position: absolute;

    .chart-container {
      width: 100%;
      height: 100%;
      display: grid;
      grid-auto-flow: column;
      grid-template: 49.5% 49.5% / 33% 33% 33% 33% 33% 33% 33% 33% 33% 33% 33% 33% 33% 33% 33%;
      gap: 1% 0.5%;
      overflow-x: scroll;
      overflow-y: hidden;
      .chart-items {
        height: 100%;
        position: relative;
        
        &-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        &-rank {
          width: 15%;
          height: 20%;
          position: absolute;
          top: 2px;
          left: 2px;
          background: #f7cb01;
          border-radius: 5px;
  
          &-text {
            color: black;
            font-size: 12px;
            font-weight: bold;
          }
        }
        
        &-title {
          position: absolute;
          bottom: 2px;
          right: 2px;
  
          &-text {
            text-align: right;
            word-break: keep-all;
            color: white;
            font-size: 12px;
            font-weight: bold;
            text-shadow: 1px 1px 3px #000000, 1px -1px 3px #000000, -1px 1px 3px #000000, -1px -1px 3px #000000;
          }
        }
      }
    }

    .v-enter-active,
    .v-leave-active {
      transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
      opacity: 0;
    }
  }
}
</style>
